@import '~styles';

.filtersTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    padding: 24px 24px 0;
}

.filtersSubtitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 0 24px 0;
}

.datePickersWrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 0 24px 0;

    :global(.ant-form-item) {
        margin: 0 !important;
        width: 100% !important;
    }

    :global(.ant-picker-input) {
        input::placeholder {
            color: $color-folk-main !important;
            font-weight: 500;
            font-size: small;
            text-align: left !important;
        }

        text-align: left !important;

        input {
            padding-left: 9px !important;
            text-align: left !important;
        }
    }

    :global(.date-picker-field) {
        border-radius: 6px !important;
        height: 48px !important;
    }
}

.regionFilter,
.pointFilter,
.deputyFilter,
.projectFilter {
    margin-bottom: 16px;
    height: 48px;
    padding: 0 24px 0;

    :global(.ant-select-selector) {
        padding-left: 20px !important;
    }

    :global(.ant-select-selection-search) {
        padding-left: 9px !important;
    }

    :global(.ant-select-arrow),
    :global(.ant-select-clear) {
        margin-right: 11px !important;
    }
}

.deputyFilter {
    margin-bottom: 32px;
}

.filterButtons {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px;

    button {
        border: none;
        box-shadow: none;
        height: 40px;

        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-gray;
        }
    }

    .buttonSubmit:enabled {
        width: 135px;
        margin-left: 24px;
        border-radius: 6px;
        background-color: $color-folk-main;

        span {
            color: white;
        }
    }

    .buttonSubmit:disabled {
        width: 135px;
        margin-left: 24px;
        border-radius: 6px;
        background-color: $color-disabled;
    }
}
