@import '~styles';

.upload-image-form {
    .upload-image-button {
        text-align: center;
        border: 0 !important;

        .upload-icon {
            margin-right: 15px;
        }
    }

    .upload-image-button span {
        color: $color-folk-secondary !important;
        font-weight: 500;
        font-size: small;
    }

    .upload-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .image-wrapper {
        position: relative;
        max-height: 121px;
        max-width: 121px;

        .uploaded-image {
            object-fit: contain;
            height: 121px;
            width: 121px;
        }

        .remove-icon {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
        }
    }
}
