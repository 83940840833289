@import '~styles';

$avatar-size: 80px;

$default-font-size: 20px;
$middle-font-size: 15px;

$middle-font-weight: 700;
$small-font-weight: 500;

$statesman-card-width: auto;

.statesman-loading-card {
    width: $statesman-card-width;
}

.statesman-card {
    display: flex;
    align-items: center;
    width: $statesman-card-width;

    .statesman-avatar {
        display: flex;
        width: $avatar-size;
        height: $avatar-size;
        align-items: center;

        span {
            display: block;
            margin: auto !important;

            svg {
                width: 36px;
                height: 36px;
            }
        }
    }

    .statesman-info {
        align-items: center;
        margin: 0 16px;
        color: $color-folk-secondary;

        .statesman-title {
            font-size: $middle-font-size;
            font-weight: $middle-font-weight;
            line-height: 24px;
            color: $color-folk-main;
        }

        .statesman-position {
            font-size: $middle-font-size;
            font-weight: $small-font-weight;
            line-height: 23px;
            color: $color-folk-plain-black;
        }

        .statesman-region {
            font-size: $middle-font-size;
            font-weight: $small-font-weight;
            line-height: 23px;
            color: $color-folk-text-gray;
        }

        .statesman-rating {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: $middle-font-weight;
            font-size: $middle-font-size;
            color: $color-folk-main;
            line-height: 24px;

            .rate-icon {
                margin-right: 12px;
            }
        }
    }

    .feedback-button-wrapper {
        margin-left: auto;

        .icon-button {
            background-color: $color-folk-main !important;
            color: $color-folk-text-selected !important;
            width: 56px !important;
            height: 56px !important;

            .icon {
                font-size: 24px !important;
            }
        }

        .icon-button:disabled {
            background-color: $color-disabled !important;
            color: $color-folk-divider !important;
            width: 56px !important;
            height: 56px !important;

            .icon {
                font-size: 24px !important;
            }
        }
    }
}
