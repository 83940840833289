@import '~styles';

.orange-buttons-modal {
    .ant-modal-body {
        padding: 0 !important;
    }

    .title {
        padding: 8px 16px 4px 16px;
        font-size: large;
        font-weight: bold;
    }

    .subtitle {
        padding: 4px 16px 12px 16px;
    }

    .content-wrapper {
        margin: 0 !important;
        box-sizing: border-box;
        width: 100%;
        min-height: 300px;
        height: auto;
        max-height: 500px;
        overflow-y: auto;
        scrollbar-width: 10px; /* Firefox */
    }

    .content-wrapper::-webkit-scrollbar {
        width: 10px; /* Safari and Chrome */
    }
}
