@import '~styles';

.favorite-item {
    width: 100%;
    min-height: 80px;
    height: auto;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    /* Border */

    border-top: 1px solid rgba(0, 0, 0, 0.08);

    .favorite-item-text {
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .favorite-item-title {
            min-height: 24px;
            height: auto;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: black;
            word-break: break-word;
        }

        .favorite-item-description {
            min-height: 24px;
            height: auto;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: grey;
        }
    }

    .favorite-item-star {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .favorite-item-star-icon {
            color: $color-folk-main;
            font-size: 25px;
            margin-right: 27.5px;
        }

        .favorite-item-star-icon :hover {
            cursor: pointer;
            color: $color-folk-main-hover;
        }
    }
}
