@import '~styles';

.news-details-wrapper {
    background: white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $color-folk-divider;
    border-radius: 8px;
    width: 100% !important;

    .news-details-title-block-wrapper {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $color-folk-divider;
        border-radius: 8px 8px 0 0;
        padding-top: 24px;

        .news-details-title-block {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: 0 24px 24px 24px;

            .news-details-title {
                word-wrap: break-word;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
                color: black;
            }

            .news-details-date {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: gray;
                padding-bottom: 8px;
            }
        }
    }

    .news-details-content-wrapper {
        margin: 24px;
        white-space: pre-wrap;

        .news-image {
            border-radius: 6px;
            max-width: 100%;
            max-height: 100vh;
        }

        .news-text {
            word-wrap: break-word;
            margin-top: 24px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: black;
        }
    }
}
