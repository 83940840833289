@import '~styles';

.body {
    display: grid;
    background-color: #fbfbfb;
    gap: 24px;
    grid-template-columns: 70.41% 29.59%;
}

.bodyMobile {
    display: grid;
    background-color: #fbfbfb;
    gap: 24px;
}

.mainContainer {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: white;

    @media screen and (max-width: 450px) {
        width: 100%;
    }
}

.headerContainer {
    position: relative;
}

.gradientHeader {
    border-radius: 8px 8px 0 0;
    background: linear-gradient(72.21deg, #193580 0%, #0071bc 100%);
    height: 128px;
    display: flex;
    align-items: center;
}

.region {
    background: $region-background;
    margin-left: 5%;
    padding: 5px 10px;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
}

.underGradientHeader {
    height: 170px;
}

.avatarHeader {
    position: absolute;
    top: 12.3%;
    left: 0;
    right: 0;
}

.pointsContainer {
    width: 100%;
}

.textTitle {
    padding: 24px 24px 0 24px;
    text-align: left;
    font-size: 28px;
    color: black;
    line-height: 36px;
    font-weight: 600;
}
