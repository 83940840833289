@import '~styles';

.chat-conversation-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .sort-tabs-wrapper {
        padding-left: 24px;
    }

    .chat-conversation-card-input-messages {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
}
