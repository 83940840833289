@import '~styles';

.dropdown {
    position: fixed !important;

    :global {
        .ant-dropdown-menu-item-group-list {
            margin: 0 !important;
        }

        .ant-dropdown-menu {
            width: 450px !important;
            max-height: 400px !important;
            overflow-y: scroll;
            overflow-x: hidden;
            border-radius: 10px !important;
            padding: 0 !important;

            .ant-dropdown-menu-title-content {
                display: flex;
                font-weight: 600;
            }

            .ant-dropdown-menu-item-group-title {
                position: sticky;
                top: 0;
                z-index: 20;
                background: white;
                text-align: center;
                font-size: 24px;
                color: $color-folk-plain-black;
                border-bottom: 1px solid $color-folk-divider;
            }

            .ant-dropdown-menu-item-active {
                cursor: default !important;
                background-color: white !important;
            }

            .ant-dropdown-menu-item {
                cursor: default !important;
            }
        }
    }
}

.notification-button {
    border: 0 !important;
    box-shadow: none !important;
}

.notification-show-all {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer !important;
}

.notification-show-all:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #3079c5;
}

.notification-badge {
    :global(.ant-badge-status-dot) {
        top: -10px;
    }
}
