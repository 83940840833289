@import '~styles';

.newsCard {
    margin-top: 24px;

    .newsCardImageContainer {
        width: 100%;

        .newsCardImage {
            width: 100%;
            max-height: 40em;
            border-radius: 6px;

            @media (max-width: ($screen-md)) {
                height: 30em;
            }

            @media (max-width: ($screen-sm)) {
                height: 20em;
            }

            @media (max-width: ($screen-xs)) {
                height: 10em;
            }
        }
    }

    .newsCardDescriptionContainer {
        margin-top: 16px;
        max-width: 200px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        word-wrap: break-word;
    }

    .newsDate {
        margin-top: 8px;
        color: $color-folk-text-gray;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }
}
