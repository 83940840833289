@import '~styles';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.layoutContainer {
    position: sticky;
    top: 0;
    z-index: $layout-z-index;
}

.layout-header {
    font-family: Montserrat, sans-serif;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, $color-folk-plain-white 0%, $color-folk-plain-white 100%);
    height: $layout-header-height;
    z-index: $layout-z-index;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 92px 0 0;
    border-bottom: 1px solid $color-folk-divider;

    .title-menu {
        padding-left: 59px;
        height: 100%;

        .element {
            display: inline-block;
            padding: 28px 0 25px 0;

            .text {
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                cursor: pointer;
                color: $color-folk-main;
            }
        }

        .selected {
            border-bottom: 1px solid $color-folk-main;
        }
    }

    .right-menu {
        margin-left: auto;

        .text {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $color-folk-plain-white;
        }
    }

    .logo {
        width: 300px;
        height: 80px;
        background-image: url(./assets/er-folk-programm.svg);
    }

    .primaryBtn {
        color: $color-folk-main;
        background: $color-folk-main;
        border-radius: 6px;
        height: 40px;

        .text {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-folk-plain-white;
        }
    }

    .linkButton {
        height: 40px;
        margin-left: 16px;
        cursor: default !important;

        .text {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-folk-secondary;
            cursor: pointer;
        }
    }
}

.layout-post-header {
    padding-top: 30px;
    display: flex;
    text-align: center;
    flex-direction: column;
}
