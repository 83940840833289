@import '~styles';

.survey-card-wrapper {
    margin-top: 24px;

    .questions-card-title {
        color: $color-folk-main;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
    }

    .questions-card-description {
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }
}
