@import '~styles';

.tabs-wrapper {
    .ant-tabs-tab .ant-tabs-tab-btn {
        color: $color-folk-text-gray !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $color-folk-main !important;
    }

    .ant-tabs-ink-bar {
        background: $color-folk-main !important;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }
}
