@import '~styles';

.questions-card-wrapper {
    .questions-card-options-list {
        width: 100%;

        .questions-card-option-text {
            padding-left: 16px;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            vertical-align: top;
        }
    }

    .vote-button {
        background-color: $color-folk-main;
    }
}
