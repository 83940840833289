@import '~styles';

.point-info-text {
    align-self: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    .pointInfoHeader {
        font-weight: 700;
    }

    .point-info-address {
        padding: 24px 0;
        display: flex;
        flex-direction: column;
    }

    .point-info-text-item {
        display: flex;
        flex-direction: column;
        min-width: 230px;
        width: calc(100% / 3 + 12);
    }

    .point-infos-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @media (max-width: ($screen-lg)) {
            gap: 24px;
        }
    }
}
