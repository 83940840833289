@import '~styles';

.text-burger-lk {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $color-folk-main !important;
}

.text-burger {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-folk-secondary !important;
}

.exit-text {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-red-button !important;
}

.drop-menu {
    box-sizing: border-box !important;
    width: 280px !important;
    background: #fff !important;
    margin-top: 45px !important;

    /* Border */

    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.06) !important;
    border-radius: 12px !important;
    padding: 0 !important;
}

.icon {
    color: $color-folk-secondary;
}
