@import '~styles';

/* Customize the label (the container) */
.checkBox {
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    width: 32px;
}

/* Hide the browser's default checkbox */
.checkBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid $color-folk-main;
}

/* On mouse-over, add a grey background color */
.checkBox:hover input ~ .checkmark {
    background-color: $background-selected;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
    content: '';
    position: absolute;
    display: none;
}

/* When the checkbox is checked, add a blue background */
.checkBox input:checked ~ .checkmark {
    background-color: $color-folk-main;
}

/* Show the checkmark when checked */
.checkBox input:checked ~ .checkmark svg {
    fill: $color-folk-plain-white;
}

.checkBox input ~ .checkmark svg {
    fill: $color-folk-main;
}
