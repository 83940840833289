@import '~styles';

.acitivities-list {
    .activities-list-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 24px;
    }

    .loader {
        padding-top: 24px;
    }
}
