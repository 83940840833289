@import '~styles';

.activity-card {
    .activity-card-header {
        color: $color-folk-plain-black;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        word-wrap: break-word;
        word-break: break-word;

        .activity-card-date {
            font-weight: 700;
            color: $color-folk-main !important;
        }
    }

    .activity-card-buttons {
        margin-top: 16px;
        display: flex;
        gap: 8px;
        justify-content: space-between;

        .activity-card-expand-icon {
            cursor: pointer;
        }

        .activity-card-action-button {
            height: 40px;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-folk-plain-white;
            background-color: $color-folk-main;
        }

        .disabled-activity-card-action-button {
            height: 40px;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            background-color: $color-disabled;
        }

        .you-participant-button {
            height: 40px;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            background-color: $color-disabled;
        }

        .activity-card-buttons-group {
            gap: 8px;
            display: flex;
            flex-direction: row;
        }
    }

    .activity-card-description {
        white-space: pre-wrap;
        margin-top: 16px;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
    }

    .activity-icons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .ant-row {
            margin-left: auto !important;
        }

        .activity-video-icon {
            width: 50px;
            height: 50px;
            font-size: 1vw;
            background: $color-folk-main;
            padding: 15px;
            border-radius: 50%;
            color: white !important;

            .anticon-video-camera {
                font-size: 20px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }

        .activity-video-icon:hover {
            filter: brightness(85%);
            cursor: pointer;
        }

        .activity-rate-icons {
            display: flex;
            font-size: 20px;

            .activity-like-icon {
                color: $color-folk-main;
                margin-right: 15px;
            }

            .activity-like-icon .anticon-like:hover {
                filter: brightness(75%);
                cursor: pointer;
            }

            .activity-dislike-icon {
                color: $color-folk-dislike;
            }

            .activity-dislike-icon .anticon-dislike:hover {
                filter: brightness(125%);
                cursor: pointer;
            }
        }
    }
}
