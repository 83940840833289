@import '~styles';

.carousel-wrapper {
    margin: 0 -8px;

    .slick-arrow {
        z-index: 1;
        color: $color-folk-main !important;
        stroke: $color-folk-main !important;
        background: $color-folk-plain-white !important;
        width: 32px !important;
        height: 32px !important;
        padding: 0 !important;
        position: absolute !important;
        top: calc(50% - 16px) !important;
        margin: 0 !important;
        border: 1px inset $color-folk-gray-background !important;

        svg {
            stroke: $color-folk-main;
        }
    }

    .slick-prev {
        left: 0 !important;
    }

    .slick-next {
        right: 0 !important;
    }
}

.main-carousel-media {
    position: relative;
    margin-bottom: 16px;
    margin-top: 24px;
}

.ant-image {
    width: 100%;

    img {
        aspect-ratio: 4/3;
        object-fit: cover !important;
        border-radius: 8px !important;
    }
}

.ant-image-mask {
    aspect-ratio: 4/3;
    border-radius: 8px !important;
}

.slick-track {
    > div {
        padding: 0 8px;
    }
}

.media-list {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
