@import '~styles';

.folk-input.ant-input {
    text-align: center;
    border: 1px solid $color-folk-main !important;
    border-radius: 4px;
}

.folk-input.ant-input::placeholder {
    color: $color-folk-main !important;
    font-weight: 500;
    font-size: small;
}

.folk-input-left.ant-input {
    border: 1px solid $color-folk-main !important;
    border-radius: 6px;
}

.folk-input-left.ant-input::placeholder {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 20px;
    color: $color-folk-main !important;
    opacity: 1;
}
