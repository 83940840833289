@import '~styles';

.yandex-map {
    width: 100%;
    height: 600px;

    @media (max-width: ($screen-lg)) {
        height: 400px;
    }
}

// Элемент из библиотеки react-yandex-maps
// stylelint-disable
.yandex-map > ymaps {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}

.ymaps-2-1-79-balloon {
    width: 240px;
    min-height: fit-content;
    padding: 0 !important;
    left: -120px !important;
    box-shadow: none !important;
    z-index: -1 !important;

    @media screen and (max-width: 450px) {
        width: 224px !important;
        height: fit-content !important;
        border-radius: 8px;
    }

    @media screen and (max-width: 396px) {
        width: 224px !important;
        left: 24% !important;
        height: fit-content !important;
        border-radius: 8px;
    }
}

.ymaps-2-1-79-map-copyrights-promo {
    display: none;
    visibility: hidden;
}

.ymaps-2-1-79-copyright__layout {
    display: none;
    visibility: hidden;
}

.ymaps-2-1-79-balloon__layout {
    overflow: unset !important;
    height: min-content !important;
    border-radius: 16px !important;
    box-shadow: 0 -2px 22px 0 #00000040 !important;
}

.ymaps-2-1-79-balloon__close {
    display: none;
    height: min-content !important;
}

.ymaps-2-1-79-balloon__tail {
    width: 36px !important;
    height: 36px !important;
    left: 102px !important;
    top: -15px;
    box-shadow: none !important;
    transform: none !important;
    background-image: url('./assets/point-cursor.svg') !important;
}

.ymaps-2-1-79-balloon__tail::after {
    display: none;
}

$balloon-content-width: 100%;
$balloon-content-box-sizing: border-box;

.ymaps-2-1-79-balloon__layout {
    height: 100% !important;
}

.ymaps-2-1-79-balloon__content {
    width: $balloon-content-width !important;
    height: 100% !important;
    box-sizing: $balloon-content-box-sizing !important;
    margin-right: 0 !important;
    padding: 16px 16px !important;
    border-radius: 20px !important;

    @media screen and (max-width: 450px) {
        width: 224px !important;
        height: fit-content !important;
    }

    ymaps {
        height: 100% !important;
        width: $balloon-content-width !important;
        box-sizing: $balloon-content-box-sizing !important;
    }
}

.balloon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 450px) {
        justify-content: start !important;
        align-items: flex-start !important;
    }
}

.point-title {
    width: 208px;
    height: min-content;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-folk-plain-black;
    font-family: 'Montserrat', serif;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    line-height: 22px;

    @media screen and (max-width: 450px) {
        width: 100% !important;
        margin-bottom: 8px !important;
    }
}

.point-address {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 21px;
    color: grey;
    width: 208px;
    text-overflow: ellipsis;
    overflow: hidden;

    @media screen and (max-width: 450px) {
        display: none;
        visibility: hidden;
    }
}

.point-image {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-bottom: 16px;

    @media screen and (max-width: 450px) {
        display: none;
        visibility: hidden;
    }

    & > img {
        border-radius: 4px;
    }
}

.point-info-button {
    color: #fff !important;
    border-radius: 6px !important;
    background-color: $color-folk-main !important;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100% !important;
    height: 30px !important;

    @media screen and (max-width: 450px) {
        width: 100px !important;
    }
}


.cluster-balloon-content {
    width: 100%;
    height: 100%;
    max-height: 358px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    @media screen and (max-width: 450px) {
        height: fit-content !important;
    }

    .point-address,
    .point-title {
        word-wrap: break-word !important;
        white-space: unset;
    }

    .divider-line {
        margin-top: 8px;
        height: 1px;
        background-color: #dedede;
    }
}

.baloon-content-list {
    @media screen and (max-width: 396px) {
        width: 190px !important;
        height: fit-content !important;
    } 
}