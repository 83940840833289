@import '~styles';

.edit-documents-header {
    margin-top: 16px;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

.edit-documents-body {
    padding: 32px 0 24px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .edit-documents-icon {
        min-width: 38px;
        min-height: 50px;
        margin-right: 38px;
    }

    .edit-documents-item {
        display: flex;
        align-items: center;

        .mobile-edit-documents-item-doc {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            word-wrap: break-word;
            max-width: 50vw;

            .edit-documents-item-doc-date {
                color: $color-folk-main;
            }
        }

        .edit-documents-item-doc {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            word-wrap: break-word;
            max-width: 40vw;

            .edit-documents-item-doc-date {
                color: $color-folk-main;
            }
        }
    }

    .remove-icon {
        cursor: pointer;
        min-width: 32px;
        min-height: 32px;
    }
}

.submit-upload {
    margin-top: 16px;
    border: 0 !important;
    padding: unset !important;
    box-shadow: unset !important;

    span {
        color: $color-folk-secondary !important;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }

    .upload-icon {
        margin-right: 15px;
    }
}
