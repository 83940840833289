.chat-conversation-card-messages-coordinator {
    height: 471px;
}

.chat-conversation-card-messages {
    height: 386px;
}

.chat-conversation-card-messages-reply {
    height: 319px;
}

.chat-conversation-card-messages-afterupload {
    height: 303px;
}

.chat-conversation-card-messages-afterupload-reply {
    height: 223px;
}

.messages-list {
    .ant-list-empty-text {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }
}
