@import '~styles';

.selectProjectField {
    :global(.ant-select-selector) {
        min-height: 48px !important;
        text-align: left !important;
        border: 1px solid $color-folk-main !important;
        border-radius: 6px !important;
        padding: 0 40px 0 4px !important;

        :global(.ant-select-selection-overflow) {
            overflow-x: auto !important;
            flex-wrap: nowrap !important;
            scrollbar-width: 4px; /* Firefox */
        }

        :global(.ant-select-selection-overflow::-webkit-scrollbar) {
            height: 4px; /* Safari and Chrome */
        }

        :global(.ant-select-selection-search-input) {
            min-height: 48px !important;
        }

        :global(.ant-select-selection-placeholder) {
            align-self: center;
            text-align: left !important;
            font-family: 'Montserrat', serif !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            color: $color-folk-main !important;
            left: 20px;
        }

        :global(.ant-select-selection-search) {
            margin-inline-start: 17px;
        }
    }

    .icon svg {
        height: 0.8em;
        width: 0.8em;
        stroke: $color-folk-main;
        margin-right: 15px;
    }
}
