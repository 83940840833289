@import '~styles';

.menu-button :hover {
    color: $color-folk-main !important;
}

.indicator-button {
    align-items: center;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: grey;
}

.indicator-menu {
    margin-left: 24px;

    :global {
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
            padding: 0;
            margin-right: 28px;
        }

        .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > .ant-menu-submenu::after {
            width: 100%;
            left: 0;
        }
    }
}
