@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import '~styles';

.layoutContainer {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: $layout-z-index;
    display: flex;
    justify-content: center;
    background-color: #fff;

    @media (max-width: ($screen-md - 1px)) {
        height: 60px;
    }
}

.menu {
    position: absolute;
    right: 5%;
    top: 25%;
}

.notification-badge {
    position: absolute !important;
    right: 3%;
    top: 10%;
}

.person-avatar {
    position: absolute;
    left: 5%;
    top: 25%;

    .person-avatar-icon {
        height: 40px;
        width: 40px;

        @media (max-width: ($screen-md - 1px)) {
            height: 32px;
            width: 32px;
        }
    }

    .text {
        line-height: 24px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: $color-folk-main;
    }
}

.drawer {
    :global {
        .ant-drawer-title {
            font-weight: 600 !important;
            font-size: 28px !important;
            line-height: 36px !important;
        }

        .ant-drawer-bottom > .ant-drawer-content-wrapper {
            border-radius: 20px;
        }

        .ant-drawer-body {
            padding: 0;
        }

        .ant-drawer-header {
            padding: 24px 23px 20px !important;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
        }

        .ant-drawer-extra {
            display: flex;
            align-items: center;
        }

        .ant-menu-title-content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .ant-menu-item {
            height: fit-content !important;
            margin-top: 16px !important;
            margin-bottom: 16px !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 24px !important;
            padding-left: 24px !important;
            display: flex;
            align-items: center;
        }

        .ant-btn-primary {
            border-radius: 6px;
            background: $color-folk-main;
            width: 100%;
        }
    }

    .divider {
        margin: 0 !important;
    }

    .close-button {
        font-size: 18px;
        color: $color-folk-main;
    }

    .primaryBtn {
        border-radius: 6px;
        height: 40px;
        background-color: $color-folk-main;
        margin-top: 8px;
        margin-bottom: 8px;

        .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .linkButton {
        font-family: 'Montserrat', sans-serif;

        .text {
            color: $color-gray !important;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
        }
    }
}
