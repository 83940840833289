@import '~styles';

:global(.modal-wrapper) {
    width: 50% !important;
    top: 127px !important;
    right: 46px !important;
    bottom: auto !important;
    left: auto !important;
    overflow-y: auto !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px !important;
}

:global(.modal-preview-hidden) {
    width: 29% !important;
}

:global(.ant-scrolling-effect) {
    overflow-y: scroll !important;
}

.chatModal {
    flex-direction: row;
    display: flex;
    width: 100% !important;
    padding: 0 !important;
    position: initial !important;

    :global(.ant-modal-content) {
        width: 100% !important;
        height: 580px;

        :global(.ant-modal-body) {
            padding: 0;
            width: 100% !important;
        }

        :global(.ant-modal-close-x) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
        }
    }

    .chatModal {
        display: flex;
        flex-direction: row;
        max-height: 580px !important;
        width: 100% !important;

        .chatModalPreview {
            width: 50%;
            padding-top: 24px;
            padding-bottom: 24px;
            max-height: 580px !important;

            .chatModalPreviewTitleWrapper {
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                .chatModalPreviewTitle {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            :global(.infinite-scroll-component) {
                overflow-y: auto !important;
                overflow-x: hidden !important;
            }

            .previewDivider {
                margin-bottom: 0 !important;
            }

            .projectFilter {
                padding-left: 24px;
                padding-right: 24px;
            }

            :global(.ant-select) {
                :global(.ant-select-selector) {
                    width: 100% !important;
                    display: flex;
                    align-items: center;
                    background-color: white !important;
                    border-color: $color-folk-main !important;
                    color: $color-folk-main !important;
                    height: 48px !important;

                    :global(.ant-select-selection-search-input) {
                        color: $color-folk-main !important;
                        font-weight: 500 !important;
                        font-size: 14px !important;
                        line-height: 24px !important;
                    }

                    :global(.ant-select-selection-placeholder) {
                        color: $color-folk-main !important;
                    }

                    :global(.ant-select-selection-item) {
                        color: $color-folk-main !important;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }

            :global(.ant-select-clear) {
                background-color: white !important;
                color: $color-folk-main !important;
            }

            :global(.IconContainer > svg) {
                stroke: $color-folk-main !important;
            }
        }

        .showPreviewIcon {
            width: 14%;
            padding: 24px;
        }

        .divider {
            height: 580px;
            margin: 0;
        }

        .missingChatTextWrapperHiddenPreview,
        .missingChatTextWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;

            .missingChatText {
                width: 60%;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
            }
        }

        .missingChatTextWrapperHiddenPreview {
            width: 100%;
        }

        .chatConverstation {
            width: 50%;
        }

        .chatConverstationFull {
            width: 100%;
        }

        .groupMembersListCardWrapper {
            width: 50%;
        }

        .groupMembersListCardWrapperFull {
            width: 100%;
        }
    }
}

div::-webkit-scrollbar {
    width: 0.7em;
}

div::-webkit-scrollbar-thumb {
    background-color: $color-folk-secondary;
    border-radius: 10px;
}
