@import '~styles';

.expandableListExpandedElementsBlockWrapper {
    background-color: $color-folk-gray-background;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;

    .expandedElementContainer {
        display: flex;
        padding: 16px 24px 16px 24px;
        border-top: 1px solid $color-folk-divider;

        .expandedElementItem {
            cursor: pointer;
            line-height: 24px;

            .titleText {
                word-break: break-word;
                font-size: 15px;
            }

            .descriptionText {
                font-size: 14px;
                word-wrap: break-word;
                color: $color-gray;
            }
        }

        .favoriteIcon {
            margin: auto 0 auto auto;
            color: $color-folk-main;
            font-size: 25px;

            :hover {
                cursor: pointer;
                color: $color-folk-main-hover;
            }
        }
    }
}
