@import '~styles';

.select-field {
    .ant-select-selector {
        text-align: center;
        border: 1px solid $color-folk-main !important;
        border-radius: 4px !important;
    }

    .ant-select-selector .ant-select-selection-placeholder {
        color: $color-folk-main !important;
        font-weight: 500;
        font-size: small;
        text-align: center;
        padding-right: 0 !important;
    }

    .ant-select-arrow .ant-select-suffix {
        color: $color-folk-main !important;
    }

    .ant-select-selection-search-input {
        text-align: center;
    }
}
