@import '~styles';

.toggle-switch {
    position: relative;
    width: 164px;
    height: 48px;
    border: 1px solid $color-folk-main;
    border-radius: 6px;

    @media (max-width: ($screen-lg - 1px)) {
        width: 100%;
    }
}

.toggle-switch label {
    position: absolute;
    width: 162px;
    height: 44px;
    background-color: $color-folk-plain-white;
    border-radius: 6px;
    cursor: pointer;

    @media (max-width: ($screen-lg - 1px)) {
        width: 100%;
    }
}

.toggle-switch input {
    position: absolute;
    display: none;
}

.slider {
    position: absolute;
    width: 100%;
    border-radius: 50px;
    transition: 0.3s;
}

.toggle-switch input:checked ~ .slider {
    background-color: $color-folk-plain-white;
}

.slider .toggle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 76px;
    height: 42px;
    border-radius: 4px;
    background-color: $color-folk-main;
    transition: 0.3s;
    color: $color-folk-plain-white;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    text-align: center;

    @media (max-width: ($screen-lg - 1px)) {
        width: 50%;
    }
}

.toggle-switch input:checked ~ .slider .toggle {
    transform: translateX(82px);
    width: 76px;
    height: 42px;
    border-radius: 4px;
    background-color: $color-folk-main;
    color: $color-folk-plain-white;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    user-select: none;

    @media (max-width: ($screen-lg - 1px)) {
        width: 49.5%;
        transform: translateX(100%);
    }
}

.map {
    color: transparent;
    transition: 0.3s;
    user-select: none;

    @media (max-width: ($screen-lg - 1px)) {
        text-align: center;
        width: 50%;
    }
}

.toggle-switch input:checked ~ .plate .map {
    color: $color-folk-secondary;
    transition: 0.3s;
}

.list {
    color: $color-folk-secondary;
    transition: 0.3s;
    user-select: none;

    @media (max-width: ($screen-lg - 1px)) {
        text-align: center;
        width: 50%;
    }
}

.toggle-switch input:checked ~ .plate .list {
    color: transparent;
    transition: 0.3s;
}

.toggle-switch .plate {
    display: flex;
    padding-left: 16px;
    gap: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;

    @media (max-width: ($screen-lg - 1px)) {
        padding-left: 0;
    }
}
