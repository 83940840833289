.container {
    display: flex !important;
    justify-content: space-around !important;
    height: 20% !important;

    :global {
        .ant-picker {
            height: 40px !important;
            max-height: 40px;
        }

        .ant-picker-input {
            margin-right: 10px;
        }

        .ant-form-item {
            width: 45%;
        }

        :global(.ant-picker-suffix) {
            padding-right: 11px !important;
        }
    }
}
