@import '~styles';

.signup-with {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-with_button {
    background-color: $color-folk-main !important;
    border-radius: 6px !important;
}

.authorization-error-message {
    width: 100%;
    text-align: center;
}
