@import '~styles';

.video-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color-folk-main;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    color: white !important;
    max-width: 48px;
    max-height: 48px;
    padding: 15px;
    border: 5px outset white;
    border-right: none;
    border-bottom: none;

    @media (max-width: $screen-xl - 1) {
        max-width: 40px;
        max-height: 40px;
        padding: 11px;
        border: 5px outset white;
        border-right: none;
        border-bottom: none;
    }

    @media (max-width: $screen-sm - 1) {
        max-width: 32px;
        max-height: 32px;
        padding: 8px;
        border: 3px outset white;
        border-right: none;
        border-bottom: none;
    }

    @media (max-width: $screen-xs - 1) {
        max-width: 24px;
        max-height: 24px;
        padding: 5px;
        font-size: 12px;
        border: 2px outset white;
        border-right: none;
        border-bottom: none;
    }
}

.player-wrapper {
    position: relative;
    aspect-ratio: 4/3;
    border-radius: 8px;
    overflow: hidden;

    video {
        object-fit: cover;
    }
}

.modal-style {
    width: fit-content !important;
    left: unset !important;
}

.modal-style :global(.ant-modal-content) {
    background: transparent !important;
    width: fit-content !important;
}

.modal-style :global(.ant-modal-body) {
    padding: 0 !important;
}
