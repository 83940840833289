@import '~styles';

.progress-card-wrapper {
    .progess-card-container {
        margin-top: 32px;

        .progress-card-description {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
        }
    }

    .progress-card-check-icon {
        color: $color-folk-main;
        margin-right: 11px;
    }
}
