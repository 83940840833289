@import '~styles';

.message-card-wrapper {
    padding: 0 24px 0;

    .message-card-header {
        display: flex;
        justify-content: space-between;

        .message-card-title {
            color: $color-folk-main;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
        }

        :global(.ant-badge-status-dot) {
            height: 10px !important;
            width: 10px !important;
        }
    }

    .message-card {
        display: flex;
        max-width: 100%;

        .message-card-info {
            width: 90%;
        }

        .message-reply {
            display: flex;

            .message-reply-divider {
                margin-left: 0;
                width: 2px;
                height: auto;
                background-color: $color-folk-main;
            }

            .message-card-reply-text {
                color: $color-folk-secondary;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                max-width: 300px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .message-user-icon-block {
            min-width: 40px;
            height: 100%;
            margin-right: 16px;

            @media screen and (max-width: 450px) {
                margin-right: 8px;
            }

            .message-user-icon {
                width: 100%;
                max-width: 40px;
                height: 40px;
            }
        }

        .message-card-username,
        .message-card-reply-username {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: $color-folk-main;
        }

        .message-card-text {
            width: 100%;
            word-wrap: break-word;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        .message-card-time {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: $color-folk-secondary;
        }

        .message-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .message-answer-button {
                background-color: $color-folk-main;
                color: $color-folk-plain-white;
                width: 101px;
                height: 32px;
                margin-top: 16px;
                border-radius: 5px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }

            .message-like-dislike {
                display: flex;
                color: $color-folk-main;
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;

                .message-like {
                    display: flex;
                    align-items: center;

                    .anticon-like > svg {
                        width: 26px;
                        height: 26px;
                    }
                }

                .message-dislike {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;

                    .anticon-dislike > svg {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }
    }

    .message-card:not(.message-card-viewed):hover {
        cursor: pointer;
    }
}
