@import '~styles';

.page-content {
    width: 100%;
    padding-bottom: 40px; // если изменяется значение необходимо согласовать с PageContext.tsx

    @media (max-width: ($screen-xxl - 1px)) {
        padding-bottom: 40px; // если изменяется значение необходимо согласовать с PageContext.tsx
    }

    @media (max-width: ($screen-xl - 1px)) {
        padding-bottom: 80px; // если изменяется значение необходимо согласовать с PageContext.tsx
    }

    @media (max-width: ($screen-md - 1px)) {
        padding-bottom: 0; // если изменяется значение необходимо согласовать с PageContext.tsx
    }

    & .no-substrate {
        padding: 0;
        margin: 0 80px;

        @media (max-width: ($screen-lg - 1px)) {
            margin: 0 40px;
        }

        @media (max-width: ($screen-md - 1px)) {
            margin: 0;
        }
    }

    & .substrate {
        background-color: #fff;
        max-width: 1000px;
        margin: 0 -32px;
        padding: 24px 32px 38px;

        @media (max-width: ($screen-md - 1px)) {
            margin: 0 -12px;
            padding: 16px;
        }
    }

    & .subtitle {
        margin-bottom: 16px !important;

        @media (max-width: ($screen-md - 1px)) {
            margin-bottom: 8px !important;
        }
    }

    &.no-bottom-padding {
        padding-bottom: 0;

        & .substrate {
            min-height: calc(100vh - 197px);

            @media (max-width: ($screen-md - 1px)) {
                min-height: calc(100vh - 145px);
            }
        }
    }
}
