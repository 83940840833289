@import '~styles';

.questionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: $color-folk-main;

    @media screen and (max-width: 450px) {
        line-height: 0;
    }
}

.questionsHeader {
    padding: 0 24px 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

.answerCard {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px !important;

    @media screen and (max-width: 450px) {
        margin-bottom: 8px !important;
    }

    :global(.ant-card-body) {
        padding: 12px;
        color: $color-folk-text-gray;
    }
}

.commonQuestionBody {
    width: 100%;
}
