.questionsPageCard {
    width: 100%;

    :global(.ant-card-body) {
        padding: 24px 0 24px !important;
    }

    .questionsHeader {
        padding: 0 24px 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
    }

    .questionsPageFilters {
        padding: 0 24px 24px;
        display: flex;
        justify-content: space-between;

        :global(.ant-select) {
            height: 48px !important;
        }

        .questionsPageFilterTitle {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }
}
