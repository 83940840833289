@import '~styles';

.newsGeneratorPageWrapper {
    box-sizing: border-box;
    height: auto;
    min-width: 260px;
    background: white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $color-folk-divider;
    border-radius: 8px;

    .newsGeneratorPageWrapperTitleBlock {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $color-folk-divider;
        border-radius: 8px 8px 0 0;
        height: 84px;
        display: flex;
        align-items: center;

        .newsGeneratorPageWrapperTitle {
            margin: 24px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: black;
        }
    }

    .newsGeneratorPageContent {
        margin: 24px;
    }
}
