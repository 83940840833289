@import '~styles';

.profile-menu {
    display: flex;

    .chat-icon-button {
        display: flex;
        align-items: center;
        margin-right: 28px;

        .chat-button {
            border: 0;
            box-shadow: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #808080;

            .chat-icon {
                margin-right: 13px;
            }
        }
    }

    .notification-icon-button {
        display: flex;
        align-items: center;
        margin-right: 52px;
    }

    .person-data {
        margin-right: 24px;
        display: flex;
        align-items: center;

        .person-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #808080;
        }

        .in-personal-pages {
            padding: 28px 0 25px 0;
            color: $color-folk-main;
            border-bottom: 1px solid $color-folk-main;
        }

        .personal-area {
            cursor: pointer;
        }
    }

    .person-avatar {
        margin-right: 21.5px;
        display: flex;
        align-items: center;

        .person-avatar-icon {
            height: 40px;
            width: 40px;
        }
    }

    .burger-menu {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
}
