@import '~styles';

.change-control-panel {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.news {
    height: 50vh !important;
    text-align: center !important;
}

.main-card {
    margin-top: 24px !important;
    width: 100% !important;

    .ant-card-body {
        padding: 0 !important;
    }

    .tool-bar {
        padding: 24px;
        justify-content: end !important;

        .ant-select-selector {
            height: 48px !important;
        }
    }
}

.blankContent {
    color: $color-folk-plain-white;
    line-height: 320px;
    text-align: center;

    & img {
        width: 100%;
        height: 320px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;

        @media screen and (max-width: 1750px) {
            height: 268px;
        }

        @media screen and (max-width: 1450px) {
            height: 238px;
        }

        @media screen and (max-width: 1300px) {
            height: 198px;
        }

        @media screen and (max-width: 1100px) {
            height: 168px;
        }

        @media screen and (max-width: 450px) {
            height: 130px;
        }

        @media screen and (min-width: 1950px) and (max-width: 2200px) {
            height: 335px;
        }
    }

    @media screen and (max-width: 450px) {
        height: 130px;
    }
}
