@import '~styles';

.activitiesGeneratorFieldsContainer {
    border: 1px solid $color-folk-divider;
    border-radius: 8px;
    width: 100%;

    .activitiesGeneratorPageWrapperTitleBlock {
        margin: 0 24px;
        height: 84px;
        display: flex;
        align-items: center;

        .activitiesGeneratorPageWrapperTitle {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: $color-folk-plain-black;
        }
    }

    :global {
        .ant-divider-horizontal {
            margin: 0;
        }
    }
}
