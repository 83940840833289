.deputy-menu-item-head {
    padding-left: 32px !important;
    padding-bottom: 24px !important;
    padding-top: 24px !important;
}

.deputy-menu-item-top {
    padding-top: 8px !important;
    padding-left: 32px !important;
    padding-bottom: 8px !important;
}

.deputy-menu-item-bottom {
    padding-top: 8px !important;
    padding-left: 32px !important;
    padding-bottom: 8px !important;
}

.deputy-menu-item {
    padding-left: 32px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
