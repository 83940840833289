@import '~styles';

.card {
    border-radius: 8px !important;
    width: 100%;
}

.header {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

.userList {
    display: flex !important;
}

.listHeader {
    padding-bottom: 2em;
}

.name {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-folk-main;
}

.region {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-folk-plain-black;
}

.checkAll {
    border-radius: 8px !important;

    i svg {
        fill: $color-folk-main;
    }
}

.button {
    margin-top: 24px;
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    align-items: center;
}

.scrollContainer {
    @media screen and (max-width: 450px) {
        max-height: 56vh;
        overflow-y: scroll;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}
