@import '~styles';

.formContainer {
    display: flex;
    justify-content: center;

    .formWrapper {
        box-sizing: border-box;
        height: auto;
        min-width: 260px;
        background: white;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $color-folk-divider;
        border-radius: 8px;
        width: 42%;

        @media screen and (max-width: 450px) {
            width: 100%;
        }

        .titleBlock {
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: $color-folk-divider;
            height: 84px;
            display: flex;
            align-items: center;

            .formTitle {
                margin: 24px;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
                color: black;
            }
        }

        .avatarContainer {
            display: flex;
            justify-content: center;

            .avatarBlock {
                margin-top: 32px;
            }

            :global(.ant-upload-select-picture-card) {
                margin-bottom: 0 !important;
                margin-right: 0 !important;
                height: 112px;
                width: 112px;
                border: none;
            }

            :global(.ant-upload) {
                border: none !important;
            }
        }
    }
}
