@import '~styles';

.site-calendar-demo-card {
    border-radius: 2px;
    width: 100%;

    .ant-picker-calendar {
        border: 1px solid #f0f0f0;
        margin: auto !important;
        width: 100% !important;
        max-width: 522px !important;
        height: 324px !important;
        border-radius: 6px;
    }

    .ant-select-selector:hover {
        border-color: $color-folk-main !important;
    }

    .ant-select-focused .ant-select-selector {
        border-color: $color-folk-main !important;
    }

    .ant-radio-button-wrapper-checked {
        color: $color-folk-main !important;
        border-color: $color-folk-main !important;
    }

    .ant-picker-cell-selected {
        .ant-picker-cell-inner {
            background: $color-folk-main !important;
        }
    }

    .ant-picker-cell-today ::before {
        border-color: $color-folk-main !important;
    }

    .activityListWrapper {
        padding: 0 24px 0;
    }

    .calendar-day-with-event {
        border-width: 1px;
        border-style: solid;
        border-color: $color-gray;
        border-radius: 1.5px;
    }

    .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
        display: none;
    }
}

.no-activities-message {
    width: 100%;
    text-align: center;
}

.ant-picker-calendar-date-value {
    height: 25px;
}
