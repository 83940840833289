@import '~styles';

.deputy-points-sort {
    margin-left: 24px;
}

.deputy-list-item {
    min-width: 100%;
}

.ant-list-item {
    padding: 0 !important;
}
