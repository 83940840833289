@import '~styles';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.layout-footer {
    background: rgb(23, 57, 132);
    background: linear-gradient(90deg, rgba(23, 57, 132, 1) 0%, rgba(5, 102, 177, 1) 100%);
    justify-content: flex-start;
    position: absolute;
    height: $layout-footer-height;
    z-index: $layout-z-index;
    width: 100%;
    display: flex;
    left: 0;
    align-items: center;
    padding: 0 4% 0 0;
    flex-direction: column;
    margin-bottom: auto;

    .menu {
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;

        .logo {
            width: 268px;
            height: 80px;
            background-image: url(./assets/er-folk-programm.svg);
        }

        .footer-menu {
            margin-left: auto;

            .element {
                display: inline-block;

                .text {
                    font-family: 'Montserrat', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: rgb(255, 255, 255);
                }
            }
        }

        .divider {
            position: absolute;
            border: 1px solid rgba(255, 255, 255, 0.18);
            width: 100%;
        }

        .copyright {
            width: 100%;
            display: flex;
            align-items: center;
            margin: 30px 4%;

            .text {
                color: rgb(255, 255, 255);
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .layout-footer {
        padding: 0 !important;

        .logo {
            margin-left: -50px;
        }

        .copyright {
            width: 100%;
            margin: 20px 5% !important;
        }
    }
}
