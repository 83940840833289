@import '~styles';

.disabled-submit-button {
    width: auto !important;
    max-width: 170px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    text-shadow: none !important;
    margin: auto;
    border-radius: 4px !important;
}

.submit-button {
    color: #fff !important;
    width: auto !important;
    max-width: 170px;
    background-color: $color-folk-main !important;
    border-radius: 4px !important;
    border-color: $color-folk-main !important;
    text-shadow: none !important;
    margin-left: 9%;
}
