.share-bar {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;

    .share-bar-content {
        margin-top: 24px;
        display: flex;
        gap: 16px;
    }
}
