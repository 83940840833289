@import '~styles';

.coordinator-point-body {
    max-width: 100%;
    cursor: pointer;
    background-color: $color-folk-plain-white;
    padding: 24px;
}

.coordinator-point-body-sel {
    max-width: 100%;
    cursor: pointer;
    padding: 24px;
    background-color: $background-selected;
}

.coordinator-point-body-sel button {
    background-color: $color-folk-main;
    color: white;
    border-radius: 6px;
}

.coordinator-point-body button {
    background-color: $color-folk-main;
    color: white;
    border-radius: 6px;
}

.coordinator-info {
    font-size: large;
    display: flex;
    gap: 1%;
    margin-bottom: 10px;
}

.coordinator-point-header {
    width: 100%;
    display: inline-block;
    font-size: large;

    .title {
        float: left;
        margin-right: 5px;
        font-weight: 500;
        word-wrap: break-word;
        word-break: break-word;
    }

    .region-name {
        float: left;
        opacity: 0.5;
    }
}

.listAvatar {
    width: 75px !important;
    height: 75px !important;
}

.listAvatar-deputy {
    width: 55px !important;
    height: 55px !important;
}

.deputy-name {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.coordinator-point-statistics {
    width: 100%;
    margin-top: 1.5%;
    display: inline-block;

    .coordinator-rating {
        font-size: 15px;
        font-weight: 600;
        float: left;
        color: $color-folk-main;
        margin-right: 5%;
    }

    .rating-svg {
        vertical-align: 2px !important;
    }

    .coordinator-group {
        font-size: 15px;
        font-weight: 600;
        float: left;
        color: $color-folk-main;
    }
}

.coordinator-divider {
    margin: 0 !important;
}

.orange-button {
    color: $color-orange-button;
}

.coordinator-buttons {
    margin-top: 2%;
    display: flex;
    gap: 5px;

    .coordinator-button-report {
        height: 40px;
        padding: 6.4px 15px;
        text-align: center;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.8;
        pointer-events: auto;
        background-color: $color-folk-main;
        color: white;
        border-radius: 6px;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    }

    .coordinator-button-report:hover {
        cursor: pointer;
    }

    .coordinator-orange-buttons {
        display: flex;
        margin-left: auto;
    }
}

.modal-spinner {
    margin-top: 20% !important;
}

.coordinator-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;

    .coordinator-icons-buttons {
        display: flex;
        grid-gap: 16px;
        align-items: center;

        .coordinator-icon-button:hover {
            background-color: $color-folk-main;
        }
    }
}

.deputy-point-question {
    margin-top: 24px;
}

.orange-buttons-modal {
    .subtitle {
        font-weight: bold;
    }
}
