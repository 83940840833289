@import '~styles';

.newsDetailsButton {
    margin-top: 16px;
    height: 40px !important;
    width: 134px;
    background-color: $color-folk-main !important;
    color: $color-folk-plain-white !important;
    border-radius: 6px !important;

    span {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }
}
