@import '~styles';

.modal-body {
    height: auto;
    margin: 0 auto;
    max-width: 256px;
    width: 100%;
    align-items: center;
}

.download-button {
    padding-left: 90px;
}

.qrcode-main {
    height: auto;
    max-width: 100%;
    width: 100%;
}

.qr-button {
    background-color: $color-folk-main !important;
    border-radius: 6px !important;
    height: 40px !important;
    width: 100%;

    span {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
}
