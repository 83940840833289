.itemStyle:not(:last-child) {
    cursor: pointer;
    margin-bottom: 24px;
}

.itemStyle:first-child {
    margin-top: 12px;
}

.avatarStyle {
    border-radius: 6px !important;
}

.buttonStyle {
    display: flex !important;
    width: 326px;
    justify-content: center;
    margin: 4px auto auto;
}

.titleStyle {
    /* stylelint-disable */
    display: -webkit-box;
    max-height: 48px;
    max-width: 330px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}
