@import '~styles';

.edit-point-media-title {
    margin-top: 32px;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

#edit-point-media-button {
    margin-top: 24px;

    .upload-media-button {
        padding: 0 !important;
        border: 0 !important;
        box-shadow: none !important;

        span {
            color: $color-folk-secondary !important;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }

        .upload-icon {
            margin-right: 15px;
        }
    }

    input {
        color: #fff !important;
        width: auto !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        background-color: $color-folk-main !important;
        border-radius: 4px !important;
        border-color: $color-folk-main !important;
        text-shadow: none !important;
        margin: auto;
    }
}

.edit-point-media-group {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 450px) {
        overflow: auto;
        flex-wrap: unset;

        ::-webkit-scrollbar {
            height: 6px !important;
        }
    }

    .edit-point-media-box {
        list-style: none;
        margin-top: 16px;
    }
}

.edit-point-remove-media-modal {
    .ant-modal-title {
        padding-top: 4px;
    }

    .ant-modal-body {
        display: none;
    }
}

.edit-point-media-file-is-new {
    position: absolute;
    left: -5px;
    top: -5px;
    height: 12px;
    width: 12px;
    background-color: #ff8000;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white;
    display: inline-block;
}
