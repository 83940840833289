@import '~styles';

.indicator-container {
    width: 100%;
    margin-top: 48px;
    font-family: 'Montserrat', serif;

    :global {
        .ant-menu-horizontal {
            margin-right: 24px;
        }

        .ant-select {
            width: 100%;
            margin-bottom: 20px;
        }

        .ant-select-selection-overflow {
            padding: 7px 0;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 8px;
        }

        .ant-select-multiple .ant-select-selection-item {
            background: transparent;
            border: none;
            margin: 0;
            color: $color-folk-main;
            font-weight: 600;
        }
    }
}

.indicator-title {
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: black;
    margin-left: 24px;
}

.indicator-content {
    margin: 24px 24px 0 24px;
}

.indicators-moq {
    height: 145px;
}
