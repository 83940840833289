@import '~styles';

.activitiesSchedulerCardWrapper {
    width: 100% !important;

    :global(.ant-card-body) {
        padding: 0 !important;
    }

    :global(.ant-tabs-nav) {
        margin: 0 0 0 0 !important;
    }

    :global(.ant-list-split .ant-list-item) {
        padding: 24px 0 !important;
    }

    :global(.ant-list-bordered) {
        border-width: 0 0 1px 0 !important;
    }

    .activitiesSchedulerPageWrapper {
        .activitiesSchedulerPageTitle {
            padding: 24px 24px 0;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
        }

        .activitiesSchedulerPageFilters {
            padding: 0 24px 0;
            display: flex;
            justify-content: space-between;

            :global(.ant-select) {
                margin-top: 16px;
                height: 48px !important;
            }

            .activitiesSchedulerPageFilterTitle {
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 16px;
                margin-left: 24px;
            }

            .activitiesSchedulerPageProjectFilter {
                width: 48% !important;
            }
        }

        .activitiesCollectionWrapper {
            font-weight: 600;
            padding: 12px 24px 0;
            width: 100%;

            .AddActivityButton {
                display: flex;
                align-items: center;
                color: $color-gray;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                border: 0;
                box-shadow: none;
                gap: 14px;
            }
        }
    }
}

.toolBar {
    padding: 0 24px !important;
    justify-content: end !important;

    :global(.ant-select) {
        height: 48px !important;
    }
}

.selector {
    height: 48px;
}

.title {
    color: black;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

.titleObject {
    color: black;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}
