@import '~styles';

.point-power-field-container {
    width: 100%;

    // Если нужно будет опять добавить звездочку, просто добавить: content: '*';
    .point-power-field-placeholder-asterisk:has(input:placeholder-shown)::after {
        color: red;
        position: absolute;
        top: 35%;
        left: 5.75em;
        transform: translateY(-50%);
        cursor: text;
    }

    .point-power-field {
        position: relative;
        height: 48px;
        border-radius: 6px !important;
        border: 1px solid $color-folk-main !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &.ant-input {
            border: 1px solid $color-folk-main;
            border-radius: 6px;
            height: 48px;
            padding: 12px 0 12px 20px !important;

            &::placeholder {
                color: $color-folk-main !important;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                position: relative;
            }
        }
    }
}
