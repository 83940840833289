.favorite-page-wrapper {
    box-sizing: border-box;
    height: auto;
    padding-top: 24px;
    min-width: 260px;
    width: 100%;

    /* White */

    background: white;

    /* Border */

    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .favorite-page-header {
        padding-left: 24px;
        height: 36px;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: black;
    }
}
