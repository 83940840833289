@import '~styles';

.formFieldsWrapper {
    .fieldsFormContainer {
        margin: 0 24px 24px 24px !important;
    }

    .subTitle {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: black;
        padding-top: 36px;
    }

    .agreementBlockContainer {
        border-top: 1px solid $color-folk-divider;

        .agreementBlock {
            margin: 24px 24px 8px 24px;

            span {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: gray;
            }
        }
    }

    .submitButtonContainer {
        margin: 0 24px 24px 24px;
        text-align: center;

        // Кнопка создать аккаунт
        :global(.ant-btn) {
            height: 40px;
            border-radius: 6px;

            span {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }
        }

        :global(.disabled-submit-button) {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            max-width: none !important;
        }

        :global(.submit-button) {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            max-width: none !important;
            margin-left: 0;
        }
    }

    :global(.ant-form-item) {
        padding-top: 16px;
        margin-bottom: 0;
    }

    :global(.ant-input) {
        height: 48px;
        text-align: left;
        border-radius: 6px;
        background-color: white;
        padding: 12px 20px;
    }

    :global(.ant-input[disabled]) {
        background-color: white;
    }

    :global(.ant-input::placeholder) {
        font-family: 'Montserrat', serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }

    // Дата рождения
    :global(.ant-picker) {
        height: 48px;
        padding: 12px 20px;
    }

    :global(.ant-picker-input input) {
        text-align: left !important;
    }

    :global(.ant-picker-input input::placeholder) {
        text-align: left !important;
    }

    :global(.ant-picker-disabled) {
        background: white !important;
    }

    :global(.ant-picker-suffix) {
        display: none;
    }

    // Телефон
    .phoneField {
        :global(.masked-text-input-field.ant-input) {
            border: 1px solid lightgray !important;
            border-radius: 6px;
        }
    }

    // Регион
    .regionField {
        :global(.ant-select-selector) {
            height: 48px !important;
            text-align: left !important;
            padding: 12px 20px !important;
            background-color: $color-folk-plain-white !important;

            :global(.ant-select-selection-item) {
                align-self: center;
            }

            :global(.ant-select-selection-search-input) {
                height: 48px !important;
                text-align: left;
                padding-left: 10px !important;
            }
        }

        :global(.ant-select-selection-placeholder) {
            align-self: center;
            text-align: left !important;
            font-family: 'Montserrat', serif !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 24px !important;
        }

        :global(.region-select-custom-placeholder-asterisk) {
            color: red;
        }

        .icon svg {
            height: 0.8em;
            width: 0.8em;
            stroke: $color-folk-divider;
            margin-right: 15px;
        }
    }

    // Адрес
    :global(.react-dadata__input) {
        height: 48px;
        text-align: left;
        padding: 12px 20px;
    }

    :global(.react-dadata__input::placeholder) {
        text-align: left !important;
        font-family: 'Montserrat', serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }
}
