@import '~styles';

.highlighted-preview {
    background-color: $background-selected;
}

.chat-preview-card-wrapper {
    width: 100%;
    cursor: pointer;
    padding: 0 24px 0;

    .ant-divider {
        margin: 0 !important;
    }

    .chat-preview-card {
        display: flex;
        width: 100%;
        padding: 18px 0 18px;

        .ant-image {
            margin: 0 !important;
            margin-right: 16px !important;
            height: 52px !important;
            width: 52px !important;

            .ant-image-img {
                height: 100% !important;
                width: 50px !important;
            }
        }

        .chat-preview-details-wrapper {
            width: 100%;

            .chat-preview-title-wrapper {
                display: flex;
                justify-content: space-between;

                .chat-preview-title {
                    max-width: 135px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-right: 8px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .chat-preview-last-message-time,
        .chat-preview-last-message {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $color-folk-secondary;
        }

        .chat-preview-last-message-time {
            max-width: 60px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .chat-preview-subtitle-wrapper {
            display: flex;
            justify-content: space-between;

            .chat-preview-last-message {
                max-width: 190px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .ant-scroll-number {
                background-color: $color-folk-main;
            }
        }
    }
}
