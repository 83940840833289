@import '~styles';

.groupItemWrapper {
    width: 100%;
    min-height: 80px;
    border-top: 1px solid $color-folk-divider;

    .groupItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 24px;

        .groupItemText {
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            .groupItemTitle {
                min-height: 24px;
                height: auto;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: black;
                word-break: break-word;
            }

            .groupItemDescription {
                min-height: 24px;
                height: auto;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: grey;
            }
        }

        .groupItemActionsBlock {
            display: flex;
            gap: 24px;

            .removeIcon {
                cursor: pointer;
            }

            .groupItemChatButton {
                color: $color-folk-main;
                border: 1px solid $color-folk-main;
                border-radius: 6px;
            }
        }
    }
}
