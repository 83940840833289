.toolBar {
    justify-content: end !important;
}

.selector {
    height: 48px;
}

.button {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    align-items: center;
}
