@import '~styles';

.expandableListExpandedElementsBlockWrapper {
    background-color: $color-folk-gray-background;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;

    .expandedElementItem {
        padding: 16px 24px 16px 24px;
        border-top: 1px solid $color-folk-divider;
        line-height: 24px;
        cursor: pointer;

        :global(.citizen-event-item-wrapper) {
            padding: unset;
            border-top: unset;

            :global(.title-line) {
                font-size: 15px;
                line-height: 28px;
                font-weight: 500;
            }

            :global(.text-line) {
                font-size: 14px;
                line-height: 28px;
                font-weight: 500;
            }

            :global(.report-button) {
                height: 32px;
            }

            :global(.actions-block-with-content) {
                :global(.actions-block) {
                    margin-top: 8px;
                }

                :global(.expand-icon) {
                    height: 32px;
                }
            }

            :global(.survey-card-wrapper) {
                :global(.questions-card-title) {
                    padding-left: 10px;
                }

                :global(.questions-card-description) {
                    padding-left: 10px;
                }
            }

            :global(.progess-card-container) {
                padding-left: 10px;
            }

            :global(.ant-space-item) {
                padding-left: 10px;
            }
        }
    }
}
