@import '~styles';

.chat-conversation-card-footer {
    padding: 0 24px 24px;

    .question-answering-info {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px 12px 0;
        position: relative;
        background-color: $color-disabled;

        .question-answering-info-username {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: $color-folk-main;
        }

        .question-answering-message {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding-left: 16px;
            max-width: 270px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .replyCloseIcon {
            cursor: pointer;
        }
    }

    .chat-conversation-send-button {
        cursor: pointer;
    }

    .image-wrapper {
        position: relative;
        display: inline-block;
        width: 30%;
        padding: 8px 0 8px;
        margin-right: 6px;

        .chat-conversation-uploaded-image {
            height: 80px;
        }

        .remove-icon {
            position: absolute;
            right: 3%;
            top: 11px;
            cursor: pointer;
        }
    }

    .ant-input-group-addon,
    .ant-input {
        height: 48px;
    }
}
