/* stylelint-disable */
@import './fonts/Montserrat/index.css';

// Для убирания стрелок в input'ах с type = number https://aguidehub.com/blog/how-to-hide-arrows-from-input-number-in-react/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

body {
    font-family: 'Montserrat', serif;
}
