@import '~styles';

.date-picker-field {
    width: 100%;
}

.ant-picker.ant-picker-disabled {
    border-color: #d9d9d9 !important;
}

.ant-picker-dropdown {
    position: absolute !important;
}

.date-picker-field.ant-picker {
    border: 1px solid $color-folk-main;
    border-radius: 4px !important;

    .ant-picker-input {
        text-align: center;
    }

    .ant-picker-input input::placeholder {
        color: $color-folk-main !important;
        opacity: 1;
        font-weight: 500;
        font-size: small;
        text-align: center;
    }

    .ant-picker-input input {
        text-align: center;
    }
}
