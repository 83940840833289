@import '~styles';

.activities-list {
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.activities-list-fixed {
    width: 100%;
    height: auto;
    overflow-y: auto;
    position: static;
}

.activity-list-item {
    padding: 24px !important;
}

.create-event-form {
    position: sticky;
    width: 100%;
    height: 50%;
    background-color: white;
    border-top: $color-folk-main 1px solid;
    bottom: 0;

    .create-event-form-collapse {
        text-align: center;
    }

    .create-event-form-collapse:hover {
        cursor: pointer;
    }
}

.infinity-scroll-component-hidden {
    position: fixed;
    visibility: hidden;
}

.no-activities-message {
    padding: 24px;
}
