@import '~styles';

.chat-conversation-card-header-wrapper {
    .chat-conversation-card-header-and-arrow {
        display: flex;
        justify-content: space-between;

        .chat-conversation-card-header-arrow {
            padding: 28px 0 0 24px;
        }

        .chat-conversation-card-header {
            width: 100%;
            padding: 24px 24px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .chat-conversation-card-title {
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
            }

            .chat-conversation-card-members {
                color: $color-folk-main;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
            }

            .chat-conversation-card-members:hover {
                cursor: pointer;
            }
        }
    }
}
