@import '~styles';

.checkbox-field {
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: $color-folk-main !important;
        }
    }

    .ant-checkbox-inner {
        border-radius: 4px !important;
        width: 24px;
        height: 24px;
        border-color: $color-folk-main !important;
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: unset !important;
    }

    .ant-checkbox-inner::after {
        margin-left: 2px !important;
    }

    .ant-checkbox-wrapper {
        align-items: center !important;
    }

    .checkbox-label-disabled {
        color: gray !important;
    }

    margin-right: 16px !important;
}
