@import '~styles';

.editPointFormContentContainer {
    .editPointFormContent {
        margin: 24px;

        .textInfo {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            margin-top: 24px;
        }

        .mobileFilters {
            display: flex;
            flex-direction: column;
        }

        .filtersWrapper {
            display: flex;
            gap: 24px;
            padding: 16px 0;

            .mobileFilter {
                height: 48px;
                width: 90vw !important;
            }

            .filter {
                height: 48px;
                width: 100%;
                min-width: 150px;

                :global(.ant-select-selector) {
                    background-color: white !important;
                    border-radius: 6px !important;
                    border: 1px solid $color-folk-main !important;
                }

                :global(.ant-select-selection-search-input) {
                    color: black !important;
                    font-family: 'Montserrat', serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 12px 10px !important;
                }

                :global(.ant-select-selection-item) {
                    color: $color-folk-plain-black !important;
                    font-family: 'Montserrat', serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 12px 10px !important;
                }

                :global(.ant-select-arrow) {
                    right: 20px;

                    svg {
                        stroke: $color-folk-main;
                    }
                }
            }
        }
    }

    .editPointFormQrCodeBlock {
        border-top: 1px solid $color-folk-divider;

        .qrCodeContent {
            margin: 24px;
        }
    }
}
