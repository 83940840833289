@import '~styles';

.like-template {
    width: auto !important;
    height: 27px !important;
    stroke: $color-folk-main;
    color: white;
}

.like-template:hover {
    cursor: pointer;
    stroke: $color-folk-main-hover;
}

.dislike-template {
    width: auto !important;
    height: 27px !important;
    stroke: $color-folk-second;
    color: white;
}

.dislike-template:hover {
    cursor: pointer;
    stroke: $color-folk-main-hover;
}

.like-template-active {
    width: auto !important;
    height: 27px !important;
    color: $color-folk-main;
    stroke: white;
}

.like-template-active:hover {
    cursor: pointer;
    color: $color-folk-main-hover;
}

.dislike-template-active {
    width: auto !important;
    height: 27px !important;
    color: $color-folk-second;
    stroke: white;
}

.dislike-template-active:hover {
    cursor: pointer;
    color: $color-folk-main-hover;
}
