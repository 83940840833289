@import '~styles';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.layout-header-tabs {
    font-family: Montserrat, sans-serif;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, $color-folk-plain-white 0%, $color-folk-plain-white 100%);
    z-index: $layout-z-index;
    position: absolute;
    top: $layout-header-height;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 92px 0 92px;

    .title-menu {
        padding-left: 59px;

        .button-active {
            cursor: pointer;
            border-bottom: 1px solid $color-folk-main;
        }

        .button {
            cursor: default;
        }

        .element {
            display: inline-block;
            padding: 16px 0;
            margin: 0 8px;
            color: $color-gray;

            > button {
                font-family: 'Montserrat', sans-serif !important;
                font-style: normal !important;
                font-weight: 500 !important;
                font-size: 13px !important;
                line-height: 20px !important;
                padding: 0 !important;
            }

            .text {
                color: $color-gray;
            }

            .active {
                color: $color-folk-main;
            }
        }
    }

    @media (max-width: ($screen-lg)) {
        display: flex;
        top: $layout-header-height-lg;
        padding: 0;

        .title-menu {
            padding: 0 23px;
            display: flex;
            overflow: auto;
            white-space: nowrap;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
