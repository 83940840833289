@import '~styles';

.headerStyle {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

.newsStyle {
    min-height: 530px !important;
}

.folkTabsWrapper {
    :global(.ant-tabs-tab) {
        padding: 16px 0 !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }

    :global(.ant-tabs-nav) {
        margin: 0 0 12px 0 !important;
    }
}

.news {
    height: 40vh !important;
    text-align: center !important;
}

.buttonStyle {
    margin-top: 10px;
    display: flex !important;
    justify-content: center;
    width: 100% !important;
}

.divStyle {
    position: relative;
    width: 100%;

    @media (max-width: ($screen-lg - 1px)) {
        height: 118px;
    }
}

.ant-tabs-nav {
    margin: 0 0 12px 0 !important;
}
