@import '~styles';

.statistics-block {
    margin: 5px;
    flex: 1 1 auto;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    height: 91%;
}

.statistics-block-value {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: $color-folk-main;
    overflow: auto;
}

.statistics-block-title {
    padding: 0;
    text-align: center;
    color: #808080;
}

.statistics-block-title::before {
    content: '';
    margin-left: -100%;
}

.statistics-block-title::after {
    content: '';
    margin-right: -100%;
}
