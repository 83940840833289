@import '~styles';

.rowDeputy {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    @media (width >= 400px) {
        justify-content: flex-start;
        min-width: 95%;
        padding: 24px 5%;
        gap: 50px;
    }

    @media (width < 400px) {
        justify-content: flex-start;
        min-width: 95%;
        padding: 24px 5%;
        gap: 30px;
    }

    @media (width <= $screen-xs) {
        justify-content: flex-start;
        min-width: 95%;
        padding: 24px 5%;
        gap: 10px;
    }
}

.arrowButton {
    align-items: flex-start;
}

.descColumn {
    height: 100%;
    width: 80%;
    font-size: large;
    margin: 0.5rem;
}

.descColumn.fromFederal {
    margin-left: 3rem;
}

.deputyDivider {
    margin: 0 !important;
}

.deputyName {
    color: $color-folk-main;
}

.backgroundSelected {
    background: $background-selected;
}

.reportButton {
    background-color: blue;
    color: white;
}

.avatarDeputy {
    width: 100%;
    height: 100%;
}

.deputy-point-question {
    margin-top: 5px;
}
