@import '~styles';

.modal-offer {
    :global {
        .ant-modal-content {
            border-radius: 10px;
            padding: 0 30px;

            & > .ant-modal-close {
                padding-right: 10px;
            }

            & > .ant-modal-header {
                border-radius: 10px;
                padding: 16px 0;
            }
        }
    }
}

.footer {
    display: flex;
    justify-content: flex-end;

    button {
        border: none;
        box-shadow: none;
        height: 40px;

        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-gray;
        }
    }

    :global(.disabled-submit-button) {
        width: 135px !important;
        margin-left: 24px;
        border-radius: 6px !important;
        background-color: $color-disabled !important;
        padding-left: unset !important;
        padding-right: unset !important;

        span {
            color: gray !important;
        }
    }

    :global(.submit-button) {
        width: 135px !important;
        margin-left: 24px;
        border-radius: 6px !important;
        background-color: $color-folk-main !important;

        span {
            color: white !important;
        }
    }
}
