@import '~styles';

.favorite-icon-button {
    display: inline-flex !important;
    border: none !important;
    justify-content: center;
    box-shadow: none !important;
    padding: 0 !important;
    background-color: unset !important;

    &::after {
        all: unset !important;
    }
}

.favorite-icon {
    color: $color-folk-main !important;
    line-height: 0 !important;
    font-size: 25px;
}

.favorite-icon:hover {
    color: $color-folk-main-hover !important;
}
