@import '~styles';

.point-power-form-container {
    padding: 16px 0;

    .point-power-field-title {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
    }

    .mobile-point-input-field-wrapper {
        display: grid !important;
        grid-template-columns: 2fr 1fr;
    }

    .point-input-field-wrapper {
        display: flex;
        gap: 24px;
        padding-top: 16px;

        .power-measure-name {
            height: 48px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            background: $color-disabled !important;
            border: 1px solid $color-folk-divider !important;
            border-radius: 6px !important;
            padding: unset;
        }

        .power-measure-name::placeholder {
            padding: 12px 20px;
        }
    }
}

.mobile-point-power-submit-button {
    width: 100% !important;
}

.point-power-submit-button {
    height: 40px !important;
    width: 128px;
    font-family: 'Montserrat', serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.point-power-submit-button:disabled {
    color: gray !important;
}
