@import '~styles';

.text-input-area-field {
    height: 150px;
}

.text-input-area-field textarea {
    border: 1px solid $color-folk-main;
    border-radius: 4px;
}

.text-input-area-field textarea::placeholder {
    text-align: center;
    vertical-align: center;
    color: $color-folk-main !important;
    opacity: 1;
    font-weight: 500;
    font-size: small;
}
