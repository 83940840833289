@import '~styles';

.user-settings-form-fields-wrapper {
    width: 450px !important;
    max-width: 100% !important;
    margin: auto !important;
    padding-bottom: 24px !important;

    @media (max-width: ($screen-sm)) {
        width: 350px !important;
    }

    @media (max-width: 380px) {
        width: 300px !important;
    }
}

.directions {
    margin-bottom: 24px;
}

.submit-user-button {
    padding-left: 30%;
}
