@import '~styles';

.disabledSubmitButton {
    width: 158px !important;
    height: 40px !important;
    text-shadow: none !important;
    border-radius: 4px !important;
}

.submitButton {
    color: #fff !important;
    width: 158px !important;
    height: 40px !important;
    background-color: $color-folk-main !important;
    border-radius: 4px !important;
    border-color: $color-folk-main !important;
    text-shadow: none !important;
}
