@import '~styles';

.points-list-pagination {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-left: 5px !important;

    .ant-pagination-next,
    .ant-pagination-prev {
        .ant-pagination-item-link {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.ant-list-bordered {
    border-width: 1px 0 1px 0 !important;
    border-style: solid !important;
    border-color: $color-folk-divider !important;
    border-radius: 0 0 8px 8px !important;
}

.object-list-item.ant-list-item {
    padding: 0;
    min-width: 0;
    cursor: pointer;
    flex-wrap: wrap;

    .object-title {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        flex-basis: 100%;
    }

    .object-region {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: grey;
        flex-basis: 100%;
    }
}

.ant-list-bordered .ant-list-item {
    padding: 16px 24px !important;
}
