@import '~styles';

.layout-content {
    height: 100%;
    width: 100%;
    background: $layout-background-color;
    padding-top: $layout-header-total-height + 24px;
    padding-left: $main-page-horizontal-padding;
    padding-right: $main-page-horizontal-padding;

    @media (max-width: $screen-double-layout) {
        padding-top: $layout-header-total-height + 70px;
        overflow-x: hidden;
    }

    @media (max-width: ($screen-lg - 1px)) {
        padding-top: $layout-header-navigation-height + 24px;
        overflow-x: hidden;
    }
}

.layout-mobile {
    height: 100%;
    width: 100%;
    background: $layout-background-color;
    padding-top: $layout-header-total-height + 24px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: ($screen-lg - 1px)) {
        padding-top: $layout-header-navigation-height + 24px;
        overflow-x: hidden;
    }

    .ant-select-arrow {
        font-size: 10px !important;
    }

    .ant-select-clear {
        margin-top: -15px !important;
        font-size: 23px !important;
        right: 22px !important;
    }
}

.layout-content-personal-pages {
    padding-top: 120px;

    @media (max-width: ($screen-lg)) {
        padding-top: 24px;
        overflow-x: hidden;
    }
}
