$page-width: 100%;
$deputy-page-margin-top: 40px;

.user-settings-form-wrapper {
    width: $page-width;
    margin-top: $deputy-page-margin-top;

    .ant-spin-nested-loading {
        display: flex;
        justify-content: center;
    }
}
