@import '~styles';

.deputy-statistics-container {
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 24px;
    background-color: white;
    margin-bottom: 24px;
    display: inline-block;
    width: 100%;
}

.deputy-statistics-body {
    display: grid;
    grid-template-columns: 50% 50%;
}

.deputy-statistics-item1 {
    overflow: auto;
    grid-row: 1/2;
    grid-column: 1/2;
}

.deputy-statistics-item2 {
    overflow: auto;
    grid-row: 1/2;
    grid-column: 2/3;
}

.deputy-statistics-item3 {
    overflow: auto;
    grid-row: 2/3;
    grid-column: 1/2;
}

.deputy-statistics-item4 {
    overflow: auto;
    grid-row: 2/3;
    grid-column: 2/3;
}

.deputy-statistics-item5 {
    overflow: auto;
    grid-row: 3/4;
    grid-column: 1/3;
}

.deputy-statistics-header {
    text-align: left;
    font-size: 28px;
    line-height: 36px;
    color: black;
    font-weight: 600;
    overflow: auto;
}

.deputy-questions-body {
    display: grid;
    grid-template-columns: 50% 50%;
}

.coordinator-questions-body {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 50% 50%;
}

.coordinator-statistics-item1 {
    min-width: 150px;
    grid-row: 1/2;
    grid-column: 1/2;
    justify-content: center;
}

.coordinator-statistics-item1:hover {
    cursor: pointer;
}

.deputy-questions-item {
    grid-row: 2/3;
    grid-column: 1/3;
}

.deputy-percent-item {
    grid-row: 3/4;
    grid-column: 1/3;
}

.statistics-value {
    font-size: 28px;
    font-weight: bold;
    color: $color-folk-main;
}
