@import '~styles';

.deputy-sort-body {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: baseline;
}

.deputy-sort-item {
    margin-right: 30px !important;
    color: grey;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    padding: 0 16px 0 0 !important;
}

.deputy-tab {
    margin-left: 40px !important;
    color: grey;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    padding: 0 16px 0 0 !important;
}

.sort-menu {
    padding-left: 24px !important;
}
