@import '~styles';

.newsPageWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width: ($screen-xl)) {
        display: flex;
        flex-direction: column-reverse;
    }

    .newsListWrapper {
        width: 65%;

        :global(.ant-card-body) {
            padding: 0 !important;
        }

        :global(.ant-divider) {
            margin-bottom: 0 !important;
        }

        :global(.ant-list-items) {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .newsCardWrapper {
                width: 48%;

                @media (max-width: ($screen-md)) {
                    width: 100%;
                }
            }

            .newsCardWrapper:first-child,
            .newsCardWrapper:nth-child(2) {
                :global(.ant-divider) {
                    visibility: hidden !important;
                }
            }

            @media (max-width: ($screen-md)) {
                .newsCardWrapper:nth-child(2) {
                    :global(.ant-divider) {
                        visibility: visible !important;
                    }
                }
            }

            @media (max-width: ($screen-md)) {
                display: flex;
                flex-direction: column;
            }
        }

        .newsCardWrapper {
            :global(.ant-divider) {
                margin-top: 0 !important;
            }
        }

        .newsList {
            padding: 0 24px 0;
        }

        @media (max-width: ($screen-xl)) {
            width: 100%;
        }
    }

    .newsFilterWrapper {
        width: 29%;
        height: fit-content;

        :global(.ant-card-body) {
            padding: 0 !important;
        }

        @media (max-width: ($screen-xl)) {
            width: 100%;
        }
    }
}
