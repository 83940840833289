@import '~styles';

.text-input-field {
    padding: 0 !important;
    position: relative;

    &.ant-input {
        border: 1px solid $color-folk-main;
        border-radius: 6px;
        height: 48px;
        padding: 12px 0 12px 20px !important;

        &::placeholder {
            color: $color-folk-main !important;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            position: relative;
        }

        &:focus::placeholder {
            opacity: 0;
        }
    }
}

.text-input-field-disabled {
    padding: 0 !important;
    position: relative;

    &.ant-input {
        border: 1px solid #d9d9d9 !important;
        border-radius: 6px;
        height: 48px;
        padding: 12px 0 12px 20px !important;

        &::placeholder {
            color: $color-folk-main !important;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            position: relative;
        }

        &:focus::placeholder {
            opacity: 0;
        }
    }
}
