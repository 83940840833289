.list-tabs {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: black;
}

.list-head {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: black;
}
