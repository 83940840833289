.hint-body {
    width: 100%;
    // stylelint-disable-next-line
    container-type: inline-size;

    .hint-image {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
        margin: 0 auto;
        width: 100%;
        max-width: 920px;

        /* stylelint-disable */
        @container (max-width: 679.9px) {
            content: url('./assets/570.png');
        }

        @container (min-width: 680px) and (max-width: 919.9px) {
            content: url('./assets/820.png');
        }

        @container (min-width: 920px) {
            content: url('./assets/920.png');
        }
        /* stylelint-enable */
    }
}
