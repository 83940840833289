@import '~styles';

.questionTextInputContainer {
    width: 100%;

    .questionTextInput {
        font-size: 14px;
        height: 48px;
        position: relative;
        border-radius: 6px !important;
        border: 1px solid $color-folk-main !important;
        font-weight: 500;
        line-height: 24px;
    }

    :global(.ant-input) {
        border: 1px solid $color-folk-main;
        border-radius: 6px;
        height: 48px;
        padding: 12px 0 12px 20px !important;
    }

    :global(.ant-input::placeholder) {
        color: $color-folk-main !important;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .questionTextInputPlaceholderAsterisk:has(input:placeholder-shown)::after {
        content: '*';
        color: red;
        position: absolute;
        top: 40%;
        left: 10em;
        transform: translateY(-50%);
        cursor: text;
    }
}
