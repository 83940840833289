@import '~styles';

.deputyMenuCardWrapper {
    margin-bottom: 24px !important;

    :global(.ant-card-body) {
        width: 100% !important;
        margin-bottom: 24px;
        padding: 0;
    }

    .deputyMenuCardTitle {
        padding: 24px 24px 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
    }

    .deputyMenuItemText {
        padding-left: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: gray;
    }

    .deputyMenuItemTextActive {
        padding-left: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-folk-main;
        text-decoration-line: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 4px;
    }

    .deputyMenuItemText:hover {
        cursor: pointer;
        color: $color-folk-main;
    }
}
