@import '~styles';

.citizen-event-item-wrapper {
    padding: 24px 0 24px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    .title-line {
        color: $color-folk-main;
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
    }

    .text-line {
        color: black;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        word-wrap: break-word;
    }

    .report-button {
        background-color: $color-folk-main;
        color: white;
        border-radius: 6px;
        height: 40px;
    }

    .report-button-disabled {
        background-color: $color-disabled;
        border-radius: 6px;
        height: 40px;
    }

    .actions-block-with-content {
        .actions-block {
            margin-top: 16px;
            display: flex;
            gap: 8px;
        }

        .expand-icon {
            color: $color-folk-main;
            font-size: 40px;
            cursor: pointer;
        }

        .likes-block {
            color: $color-folk-main;
            line-height: 24px;
            font-size: 15px;
            margin-left: auto;
            display: flex;
            gap: 24px;
            font-weight: 600;

            .likes-info {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .IconContainer {
                height: 26px;
                width: 26px;
                cursor: pointer;
            }

            .dislikes-info {
                display: flex;
                align-items: center;
                gap: 8px;

                .IconContainer {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
