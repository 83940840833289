@import '~styles';

.citizen-events-page-wrapper {
    box-sizing: border-box;
    height: auto;
    min-width: 260px;
    background: white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $color-folk-divider;
    border-radius: 8px;
    width: 100%;

    .citizen-events-page-title-block {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $color-folk-divider;
        border-radius: 8px 8px 0 0;
        height: 84px;
        display: flex;
        align-items: center;

        .citizen-events-page-title {
            margin: 24px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: black;
        }
    }

    .citizen-events-content {
        margin: 24px;

        .citizen-events-filters-block {
            display: flex;
            gap: 24px;

            .filter-with-title-block {
                display: flex;
                gap: 16px;
                flex-direction: column;
                width: 100%;

                .filter-title {
                    font-family: 'Montserrat', serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: black;
                }

                .ant-select-selector {
                    height: 48px !important;
                    justify-content: center;
                    align-items: center;

                    .ant-select-selection-search-input {
                        height: 48px !important;
                    }
                }
            }

            @media (max-width: ($screen-md)) {
                display: flex;
                flex-direction: column;
            }
        }

        .navigation-tabs {
            padding-top: 12px;

            .ant-tabs {
                line-height: 24px !important;
            }

            .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
                height: 1px;
            }

            .ant-tabs-nav {
                margin: 0;
            }
        }
    }

    .calendar-day-with-event {
        border-width: 1px;
        border-style: solid;
        border-color: $color-gray;
        border-radius: 1.5px;
    }

    .ant-picker-calendar {
        margin: 24px auto 32px auto !important;
    }
}
