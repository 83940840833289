@import '~styles';

.indicator-statistic-container {
    width: 100%;
    height: 148px;
    background: white;
    border: 1px solid $color-folk-divider;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    font-family: 'Montserrat', serif;
    color: $color-folk-main;
    font-style: normal;
    font-weight: 600;

    .indicator-statistic-title {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        margin: 16px 0;
        text-align: center;
    }

    .indicator-statistic-content {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
    }

    .indicator-statistic-plan {
        border-right: 1px solid $color-folk-divider;
    }

    .indicator-statistic-plan,
    .indicator-statistic-fact {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        font-size: 28px;
        line-height: 36px;
    }

    .indicator-statistic-label {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: $color-folk-plain-black;
    }
}
