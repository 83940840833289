@import '~styles';

.controlPointModal {
    :global(.ant-modal-body) {
        padding: unset;
    }

    :global(.ant-modal-content) {
        border: 1px solid $color-folk-divider;
        border-radius: 8px;
    }

    :global(.ant-modal-close) {
        display: none;
    }

    .modalContainer {
        .modalHeaderContainer {
            padding: 24px;
            display: flex;
            align-items: center;

            .modalHeader {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
            }

            .closeModal {
                margin-left: auto;
                cursor: pointer;
                color: gray;
                margin-right: 8px;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .modalFormContainer {
            border-top: 1px solid $color-folk-divider;

            .modalFormFooterContainer {
                border-top: 1px solid $color-folk-divider;

                .modalFormFooter {
                    padding: 24px;
                    display: flex;
                    gap: 32px;
                    flex-direction: row-reverse;

                    .cancelButton {
                        height: 40px;

                        span {
                            font-family: 'Montserrat', serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                            color: gray;
                        }
                    }

                    .submitButton {
                        width: 120px;

                        span {
                            font-family: 'Montserrat', serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .submitButton:disabled {
                        span {
                            color: gray;
                        }
                    }
                }
            }
        }
    }
}
