.confirmModal {
    :global {
        .ant-modal-content {
            border-radius: 6px;
        }

        .ant-modal-close-x {
            width: 28px;
            height: 28px;
            line-height: 28px;
        }

        .ant-modal-body {
            display: flex;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            gap: 8px;
        }

        .ant-modal-footer {
            text-align: unset;
        }
    }
}
