@import '~styles';

.switch-field-wrapper {
    display: flex;

    .switch {
        display: inline-block;

        .ant-switch-checked {
            background: $color-folk-main;
        }
    }

    .switch-field-title {
        font-size: 14px;
        margin-left: 20px;
        width: available;
        display: inline-block;
    }
}
