@import '~styles';

.disabledButton {
    cursor: not-allowed !important;
    background: $color-disabled !important;
    border-color: $color-disabled !important;
    color: $color-folk-plain-black !important;

    span {
        font-weight: 500;
    }
}
