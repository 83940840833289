@import '~styles';

.notificationCardWrapper {
    padding: 0 32px 0;

    .notificationCardHeader {
        display: flex;
        justify-content: space-between;

        .notificationCardTitle {
            color: $color-folk-main;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
        }

        :global(.ant-badge-status-dot) {
            height: 10px !important;
            width: 10px !important;
        }
    }

    .notificationCardDescription {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 2px;
        word-break: break-word;
    }

    .notificationCardDate {
        color: $color-folk-secondary;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .invitationRespondButtons {
        margin-top: 24px;

        .acceptInvitationButton {
            margin-right: 16px;
            background-color: $color-folk-main;
            color: $color-folk-plain-white;
            border: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border-radius: 6px;
        }

        .declineInvitationButton {
            box-shadow: none;
            color: $color-gray;
            border: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .invitationResponse {
        margin-top: 24px;
        width: 100%;
        height: 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-disabled;
    }
}

.messageContent {
    bottom: 0;

    .messageContentText {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .messageContentButton {
        box-shadow: none;
        border: 0 !important;
        color: $color-folk-main;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
    }
}
