@import '~styles';

.expandableListForMenuWrapper {
    .titleContentContainer {
        padding: 16px 24px 16px 24px;
        border-top: 1px solid $color-folk-divider;
        font-family: 'Montserrat', serif;
        font-style: normal;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;

        .expandableListTitleBlock {
            display: flex;
            gap: 12px;

            .count {
                color: $color-gray;
            }

            .disabledListName {
                color: $color-folk-divider;
                cursor: not-allowed;
            }

            .expandIcons {
                margin-left: auto;
                color: $color-gray;

                .icon {
                    cursor: pointer;
                }
            }

            .underlined {
                text-decoration-line: underline;
                text-decoration-thickness: 3px;
                text-underline-offset: 4px;
            }
        }
    }

    .selected {
        font-weight: bold;
        font-size: 18px;
    }
}
