@import '~styles';

.folk-btn-style {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border-radius: 6px !important;
    height: auto !important;

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
}

.folk {
    &-primary {
        background: $color-folk-main !important;
        border-color: $color-folk-main !important;
    }

    &-primary[disabled] {
        background: $color-disabled !important;
        border-color: $color-disabled !important;
        color: $color-folk-plain-black !important;

        span {
            font-weight: 500;
        }
    }

    &-default {
        border-color: $color-folk-main !important;
        color: $color-folk-main !important;
    }
}
