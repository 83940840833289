@import '~styles';

.fieldsContainer {
    padding: 24px 24px 8px 24px;

    :global(.ant-form-item) {
        margin-bottom: 16px;
    }

    :global(.ant-picker) {
        padding: 0 !important;

        &:hover {
            border-color: $color-folk-main;
        }
    }

    :global(.ant-picker-input) {
        height: 48px;
        text-align: left !important;

        :global(.ant-picker-clear) {
            margin-right: 12px;
        }

        :global(input) {
            text-align: left !important;
            padding-left: 20px;

            &::placeholder {
                color: $color-folk-main !important;
                font-family: 'Montserrat', serif !important;
                font-style: normal !important;
                font-weight: 500 !important;
                font-size: 14px !important;
                line-height: 24px !important;
                text-align: left !important;
            }
        }
    }

    :global(.date-picker-field.ant-picker) {
        border-radius: 6px !important;
    }

    .checkboxFieldsContainer {
        padding-top: 8px;

        :global(.ant-checkbox-wrapper) {
            align-items: center;

            :global(.ant-checkbox + span) {
                padding-left: 16px;
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                margin-top: 2px;
                color: black;
            }
        }
    }
}
