@import '~styles';

.citizenMenu {
    margin-bottom: 24px !important;

    .titleBlock {
        font-family: 'Montserrat', serif;
        font-style: normal;
        line-height: 36px;
        font-size: 28px;
        font-weight: 600;
        padding: 24px;
    }

    :global(.ant-card-body) {
        padding: unset;
    }
}
