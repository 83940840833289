@import '~styles';

.message-wrapper-highlighted,
.message-wrapper {
    padding: 8px 16px !important;
}

.message-wrapper:not(:last-child) {
    border-bottom: 1px solid $color-folk-divider;
}

.message-wrapper:hover {
    cursor: pointer;
    background-color: $color-folk-gray-background;
}

.message-wrapper-highlighted {
    background-color: $color-folk-gray-background;
}
