@import '~styles';

.wrapper {
    padding-bottom: 10px;
    font-style: normal;
}

.title {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    padding: 24px 0;
}

.noDocumentMessage {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $color-gray;
}

.document {
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

.document-icon {
    color: $color-folk-main;
    margin-left: 12px;
    margin-right: 24px;
    width: 38px;
    height: 50px;
}

.documentTextContainer {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    word-wrap: break-word;
    word-break: break-word;

    .documentDate {
        color: $color-folk-main;
        padding-right: 8px;
    }
}
