@import '~styles';

.IconContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        display: block;
    }
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
