@import '~styles';

.chatsPageWrapper {
    width: 100%;
    margin-bottom: 24px !important;

    :global(.ant-card-body) {
        padding: 24px !important;
    }

    .chatsHeader {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 24px;
    }

    .projectFilter {
        :global(.ant-select) {
            :global(.ant-select-selector) {
                width: 100% !important;
                display: flex;
                align-items: center;
                background-color: white !important;
                border-color: $color-folk-main !important;
                color: $color-folk-main !important;
                height: 48px !important;

                :global(.ant-select-selection-search-input) {
                    color: $color-folk-main !important;
                    font-weight: 500 !important;
                    font-size: 14px !important;
                    line-height: 24px !important;
                }

                :global(.ant-select-selection-placeholder) {
                    color: $color-folk-main !important;
                }

                :global(.ant-select-selection-item) {
                    color: $color-folk-main !important;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        :global(.ant-select-clear) {
            background-color: white !important;
            color: $color-folk-main !important;
        }

        :global(.IconContainer > svg) {
            stroke: $color-folk-main !important;
        }
    }
}

.chatConversationPageWrapper {
    margin-bottom: 24px;
    width: 100%;

    :global(.ant-card-body) {
        padding: 0 !important;
    }

    .returnIconWrapper {
        height: 50px;
    }
}
