@import '~styles';

.expandableListExpandedElementsBlockWrapper {
    background-color: $color-folk-gray-background;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;

    .expandedElementItem {
        cursor: pointer;
        padding: 16px 24px 16px 24px;
        border-top: 1px solid $color-folk-divider;
        line-height: 24px;

        .titleText {
            word-wrap: break-word;
            font-size: 15px;
        }

        .descriptionText {
            font-size: 14px;
            color: $color-gray;
        }
    }
}
