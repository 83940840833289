@import '~styles';

.edit-control-point-header {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

.edit-control-point-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.7em;

    .edit-control-point-info {
        width: 90%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 450px) {
            width: 80%;
        }

        .edit-control-point-cursor {
            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: $color-folk-main !important;
                }
            }

            .ant-checkbox-inner {
                border-radius: 4px !important;
                width: 24px;
                height: 24px;
                border-color: $color-folk-main !important;
            }

            .ant-checkbox-inner::after {
                margin-left: 2px !important;
            }

            margin-right: 16px !important;
        }
    }

    .edit-icon {
        cursor: pointer;
    }

    .edit-control-point-text {
        display: flex;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        width: 100%;

        .control-point-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .control-point-date {
            margin-right: 4px;
            color: $color-folk-main;
        }
    }
}

.edit-control-point-add {
    margin-top: 24px;
    border: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;

    span {
        color: $color-folk-secondary !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }

    .add-icon {
        margin-right: 12.5px;
    }
}

.edit-control-divider {
    height: 30px !important;
    border-color: $color-folk-main !important;
    margin-left: 11px !important;
}
