@import '~styles';

.activityCardWrapper {
    .activityTextWrapper {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        .activityDate {
            font-weight: 700 !important;
            color: $color-folk-main;
        }

        .activityName {
            cursor: pointer;
            word-wrap: break-word;
            word-break: break-word;
        }
    }

    .buttonsWrapper {
        margin-top: 24px;
        display: flex;

        .resultButton {
            height: 40px;
            background-color: $color-folk-main;
            color: $color-folk-plain-white;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            border-radius: 6px;
        }

        .generateResultButton {
            height: 40px;
            background-color: $color-orange-button;
            color: $color-folk-plain-white;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            border-radius: 6px;
        }
    }
}
