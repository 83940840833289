@import '~styles';

.like {
    width: auto;
    height: 27px;
}

.numbers {
    font-style: normal;
    color: $color-folk-main;
    padding-left: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    height: 100%;
    cursor: default;
}

.numbers-container {
    width: 16px;
    height: 24px;
}

.button {
    display: inline-flex !important;
    border: none !important;
    justify-content: center;
    box-shadow: none !important;
    padding: 0 !important;
    background-color: unset !important;

    &::after {
        all: unset !important;
    }
}
