@import '~styles';

.deputy-sort-body {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: baseline;
}

.ant-menu-item-selected {
    color: $color-folk-main !important;
}

.ant-menu-item-selected::after {
    border-bottom: 2px solid $color-folk-main !important;
    left: 0 !important;
}

.menu-button {
    color: grey;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0 16px 0 0 !important;
}

.menu-button :hover {
    color: $color-folk-main !important;
}
