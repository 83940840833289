@import '~styles';

.masked-text-input-field.ant-input {
    text-align: center;
    border: 1px solid $color-folk-main;
    border-radius: 4px;
}

.ant-input[disabled] {
    border-color: #d9d9d9 !important;
}

.masked-text-input-field.ant-input::placeholder {
    color: $color-folk-main !important;
    font-weight: 500;
    font-size: small;
}
