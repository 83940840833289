$layout-header-height: 80px;
$layout-header-navigation-height: 64px;
$layout-header-total-height: $layout-header-height + $layout-header-navigation-height;
$layout-z-index: 70;
$main-page-horizontal-padding: 1vw;
$layout-footer-height: 165px;

$color-folk-plain-black: #000;
$color-folk-plain-white: #fff;
$color-folk-divider: rgba(0, 0, 0, 0.08);
$color-folk-gray-background: rgba(0, 0, 0, 0.03);
$color-folk-main: #3079c5;
$color-folk-second: #194d8f;
$color-folk-main-hover: #3990eb;
$color-folk-secondary: #808080;
$color-folk-text-selected: #fff;
$color-folk-text-gray: #aaa;
$color-folk-dislike: #004c92;
$background-selected: #f0f0f0;
$avatar-background-color: #d6e8f6;
$color-gray: #808080;
$layout-background-color: #fbfbfb;
$color-disabled: #f2f2f2;
$region-background: #29abe2;
$color-orange-button: #ef983f;
$color-red-button: #d5524c;
$color-red-star: #e93323;
$color-black-content: rgba(0, 0, 0, 0.08);

$layout-header-height-xxl: 91px; // если изменяется значение необходимо согласовать с /constants/styles.ts
$layout-header-height-xl: 70px; // если изменяется значение необходимо согласовать с /constants/styles.ts
$layout-header-height-lg: 60px; // если изменяется значение необходимо согласовать с /constants/styles.ts
$layout-header-height-md: 48px; // если изменяется значение необходимо согласовать с /constants/styles.ts
$layout-bottom-menu-height: 70px; // если изменяется значение необходимо согласовать с /constants/styles.ts

$screen-double-layout: 1335px;
$screen-xxl: 1600px;
$screen-xl: 1200px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 576px;
$screen-xs: 360px;
