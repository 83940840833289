@import '~styles';

.chat-message-card-wrapper {
    padding: 0 24px 0;

    .chat-message-card {
        display: flex;

        .chat-message-reply {
            display: flex;
            cursor: pointer;
            margin-bottom: 4px;

            .chat-message-reply-divider {
                margin-left: 0;
                border: 1px solid $color-folk-main;
                height: auto;
            }

            .chat-message-card-reply-attachments {
                display: flex;
                gap: 4px;

                .chat-message-card-reply-image {
                    width: 50px;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
            }

            .chat-message-card-reply-text {
                color: $color-folk-secondary;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                max-width: 220px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .chat-message-user-icon {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        .chat-message-card-info {
            width: 100%;

            .chat-message-card-info-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .remove-message-icon {
                    color: $color-red-button;
                    cursor: pointer;
                }

                .chat-message-imgs-wrapper {
                    display: flex;
                    gap: 4px;

                    .chat-message-img {
                        height: 100px;
                        width: 100px;
                    }
                }

                .ant-image-mask {
                    height: 100px !important;
                    width: 100px !important;
                }

                .chat-message-card-username,
                .chat-message-card-reply-username {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: $color-folk-main;
                }

                .chat-message-card-text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 230px;
                    word-wrap: break-word;
                }
            }

            .chat-message-card-time {
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: $color-folk-secondary;
            }

            .chat-message-buttons-coordinator,
            .chat-message-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .chat-message-answer-button {
                    display: flex !important;
                    align-items: center !important;
                    background-color: $color-folk-main;
                    color: $color-folk-plain-white;
                    height: 32px;
                    margin-top: 8px;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }

                .chat-message-like-dislike {
                    display: flex;
                    color: $color-folk-main;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23px;

                    .chat-message-like {
                        display: flex;
                        align-items: center;

                        .anticon-like > svg {
                            width: 26px;
                            height: 26px;
                        }
                    }

                    .chat-message-dislike {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;

                        .anticon-dislike > svg {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
            }

            .chat-message-buttons-coordinator {
                justify-content: flex-end !important;
            }
        }
    }
}

@keyframes blink {
    0% {
        background-color: $color-folk-text-gray;
    }

    50% {
        background-color: $color-folk-plain-white;
    }

    100% {
        background-color: $color-folk-text-gray;
    }
}

.focus {
    animation-name: blink;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    text-shadow: 0 0 10px $color-folk-plain-white;
    padding-top: 12px;

    .chat-message-card {
        .chat-message-reply {
            .chat-message-card-reply-text {
                text-shadow: 0 0 10px $color-folk-plain-white;
            }
        }

        .chat-message-card-info {
            width: 100%;

            .remove-message-icon {
                text-shadow: 0 0 10px $color-folk-plain-white;
                cursor: pointer;
            }

            .chat-message-card-time {
                text-shadow: 0 0 10px $color-folk-plain-white;
            }

            .chat-message-card-info-header {
                .chat-message-card-username,
                .chat-message-card-reply-username {
                    text-shadow: 0 0 10px $color-folk-plain-white;
                }
            }

            .chat-message-buttons-coordinator,
            .chat-message-buttons {
                .chat-message-like-dislike span {
                    text-shadow: 0 0 10px $color-folk-plain-white;
                }
            }
        }
    }
}

.confirm-remove-modal {
    .ant-modal-content {
        border-radius: 8px !important;

        .ant-modal-header {
            border-radius: 8px !important;
        }

        .ant-modal-body {
            font-weight: 600 !important;
            font-size: 15px !important;
            line-height: 24px !important;
        }

        .ant-btn-primary {
            background-color: $color-folk-main;
        }

        .ant-btn {
            border: none;
            border-radius: 4px !important;
        }
    }
}
