@import '~styles';

.info-card div:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .title-wrapper {
        padding-left: 24px !important;
    }
}

.info-card {
    width: 100%;
}

.skeleton div:first-child {
    padding: 0 24px !important;

    ul {
        margin-top: 48px !important;
    }
}
