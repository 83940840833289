@import '~styles';

.expandedElementShowAll {
    padding: 14px 24px 14px 24px;
    border-top: 1px solid $color-folk-divider;
    line-height: 20px;
    font-size: 13px;
    color: $color-gray;

    span {
        cursor: pointer;
    }
}
