@import '~styles';

.main-news {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0 24px 0;

    .main-news-image-container {
        position: relative;
        overflow: hidden;
        height: 606px;
        max-width: 808px;

        .main-news-image {
            width: 100%;
            height: fit-content;
            border-radius: 6px;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;

            @media (max-width: ($screen-md)) {
                height: 30em;
            }

            @media (max-width: ($screen-sm)) {
                height: 20em;
            }

            @media (max-width: ($screen-xs)) {
                height: 10em;
            }
        }
    }

    .main-news-description-container {
        margin-top: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        word-wrap: break-word;
    }

    .main-news-date {
        margin-bottom: 8px;
        margin-top: 8px;
        color: $color-folk-text-gray;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }
}
