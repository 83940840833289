@import '~styles';

.wrapper {
    padding-top: 24px;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
}

.status-content {
    display: flex;
    flex-direction: column;
    border-radius: 12px !important;

    > div {
        padding: 16px !important;
    }

    ul {
        padding-left: 7px;

        li {
            > div:first-child {
                border-left: 1px solid $color-folk-main !important;
            }

            > div:nth-child(2n) {
                padding: 0;
            }

            > div:last-child {
                margin-left: 28px;
                min-height: 24px;
            }
        }

        li:last-child {
            padding-bottom: 0;

            > div:last-child {
                min-height: unset !important;
            }
        }
    }

    .st-title {
        color: $color-folk-main;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
    }

    .st {
        padding-top: 24px !important;
    }

    .st-date {
        color: $color-folk-main;
        font-weight: 600;
    }

    .st-text {
        font-weight: 600;
    }

    .st-check {
        &-icon {
            background: $color-folk-main;
            border-radius: 50%;
            padding: 2px;
            width: 24px;
            height: 24px;
        }

        &-empty {
            background: #29abe2;
            border-radius: 50%;
            padding: 2px;
            width: 24px;
            height: 24px;
        }
    }
}
