@import '~styles';

.info-card {
    width: 100% !important;

    > div:first-child {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .title-wrapper {
        padding-left: 24px;
    }
}

.skeleton div:first-child {
    padding: 0 24px !important;

    ul {
        margin-top: 48px !important;
    }
}

.wrapper {
    padding-top: 20px !important;
}

.point-content-wrapper {
    padding: 0 24px;
}

.point-join-button {
    margin-left: auto;
}

.point-info-label {
    word-wrap: break-word;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

.divider {
    background-color: $color-folk-main;
}
