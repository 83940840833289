@import '~styles';

.deputy-page-wrapper {
    width: 100%;
    box-sizing: content-box;

    .project-title {
        margin-bottom: 24px;
    }
}

.divider {
    background-color: $color-folk-divider;
}
