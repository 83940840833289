@import '~styles';

.newsGeneratorFieldsContainer {
    .newsGeneratorFormFieldsWrapper {
        margin: 24px;

        .newsGeneratorSelectors {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            :global(.ant-select-selection-search) {
                left: 20px;
            }

            :global(.ant-select-selector) {
                padding: 0 20px;
            }

            :global(.ant-select-arrow) {
                right: 20px;
            }

            :global(.ant-select-clear) {
                right: 20px;
            }
        }

        .selectorWrapper {
            @media screen and (max-width: $screen-md) {
                width: 100%;
            }

            width: 32%;
        }

        .uploadImage {
            display: flex;

            :global(.ant-form-item) {
                margin-bottom: unset;

                :global(.ant-btn) {
                    box-shadow: none;
                }
            }
        }

        .activityFilter,
        .pointFilter,
        .projectFilter {
            height: 48px;

            :global(.ant-select-selection-item) {
                color: $color-folk-plain-black !important;
            }
        }

        .newsGeneratorSubtitle {
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }

    .newsButtonWrapper {
        border-top: 1px solid $color-folk-divider;

        .newsButton {
            padding: 24px;

            :global(.ant-form-item) {
                margin-bottom: unset;
            }

            :global(.ant-form-item-control-input-content) {
                span {
                    display: unset !important;
                    padding: 0 !important;
                }
            }

            :global(.submit-button) {
                max-width: unset;
                width: 100% !important;
                margin-left: 0;
            }

            button {
                padding-top: 8px !important;
                padding-bottom: 8px !important;
                border-radius: 6px !important;
                height: auto !important;

                span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}
