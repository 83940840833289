@import '~styles';

.not-reported-activity {
    padding: 8px 16px;

    .activity-date-time {
        color: $color-folk-main;
        font-weight: bold;
    }

    .activity-name {
        font-weight: bold;
    }
}

.not-reported-activity:not(:last-child) {
    border-bottom: 1px solid $color-folk-divider;
}

.not-reported-activity:hover {
    background-color: $color-folk-gray-background;
}

.not-reported-activity.active:hover {
    cursor: pointer;
}
