@import '~styles';

.orange-button {
    &-primary {
        margin-left: 8px;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: white;
        background: $color-orange-button !important;
        border-color: $color-orange-button !important;
    }

    &-default {
        background: $color-orange-button !important;
        border-color: $color-orange-button !important;
        font-weight: bold;
    }
}
