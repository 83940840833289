@import '~styles';

.groupListmembersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;

    .closeIcon {
        cursor: pointer;
    }
}

.groupListmemberCardWrapper {
    display: flex;
    flex-direction: column;

    .groupListmemberCard {
        padding: 0 24px 0;
        display: flex;
        justify-content: space-between;

        .groupListmemberRemovedInfo,
        .groupListmemberInfo {
            display: flex;
            align-items: center;

            .groupListmemberIcon {
                margin-right: 16px;
                width: 40px;
                height: 40px;
            }

            .groupListmemberName {
                color: $color-folk-main;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
            }
        }

        .groupListmemberRemovedInfo {
            opacity: 0.5;
        }

        .groupListMemberRemoveIcon,
        .groupListMemberRecoverIcon {
            cursor: pointer;
        }
    }
}

.groupListmemberRemoveModal {
    :global(.ant-modal-content) {
        height: 280px !important;
        width: 600px !important;
        border-radius: 8px !important;

        @media screen and (max-width: 450px) {
            width: 100% !important;
            height: 100% !important;
        }

        :global(.ant-modal-header) {
            border-radius: 8px !important;
        }

        :global(.ant-btn-primary) {
            background-color: $color-folk-main;
        }

        :global(.ant-select-selector) {
            border-radius: 4px !important;
        }

        :global(.ant-btn-primary[disabled]) {
            background-color: #f5f5f5;
        }

        :global(.ant-btn) {
            border: none;
            border-radius: 4px !important;
            box-shadow: none !important;
        }
    }

    .groupListmemberRemoveModalDescription {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }

    @media screen and (max-width: 450px) {
        :global(.ant-select) {
            width: 100%;
        }
    }

    :global(.ant-select-selector) {
        width: 350px !important;
        display: flex;
        align-items: center;
        border-color: $color-folk-main !important;
        height: 48px !important;

        @media screen and (max-width: 450px) {
            width: 100% !important;
        }

        :global(.ant-select-selection-placeholder) {
            color: $color-folk-main !important;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    :global(.ant-select-arrow) {
        color: $color-folk-main !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    :global(.ant-select-selection-item) {
        color: $color-folk-main !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

.groupListmembersFilter {
    padding: 24px 24px 0;

    .membersFilter {
        border: 1px solid $color-folk-main;
        border-radius: 6px;
        height: 48px;
    }

    .membersFilter::placeholder {
        color: $color-folk-main;
    }
}

.infiniteScrollWrapper {
    height: 400px !important;
    overflow: auto;
}
