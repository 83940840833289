@import '~styles';

.ant-carousel {
    width: 100% !important;

    @media (max-width: ($screen-md - 1px)) {
        padding: 0 30px !important;
    }

    @media (max-width: ($screen-sm - 1px)) {
        padding: 0 23px !important;
    }

    .slick-dots {
        bottom: 0 !important;
    }
}

.carousel {
    height: 320px !important;

    @media screen and (max-width: 1750px) {
        height: 268px !important;
    }

    @media screen and (max-width: 1450px) {
        height: 238px !important;
    }

    @media screen and (max-width: 1300px) {
        height: 198px !important;
    }

    @media screen and (max-width: 1100px) {
        height: 168px !important;
    }

    @media screen and (max-width: 450px) {
        height: 130px !important;
    }

    @media screen and (min-width: 1950px) and (max-width: 2200px) {
        height: 335px !important;
    }
}

.carousel > .slick-list > .slick-track > .slick-slide {
    padding: 0 !important;
}

.carousel > .slick-dots li {
    width: unset !important;

    button {
        width: 4px !important;
        height: 4px !important;
        border-radius: 100% !important;
    }
}

.carousel > .slick-dots li.slick-active button {
    width: 24px !important;
    height: 4px !important;
    border-radius: 16px !important;
    background: $color-folk-plain-white !important;
}

.content {
    margin: 0;
    height: 320px;
    border-radius: 8px;

    @media screen and (max-width: 1750px) {
        height: 268px;
    }

    @media screen and (max-width: 1450px) {
        height: 238px;
    }

    @media screen and (max-width: 1300px) {
        height: 208px;
    }

    @media screen and (max-width: 1100px) {
        height: 178px;
    }

    @media screen and (min-width: 1950px) and (max-width: 2200px) {
        height: 345px;
    }
}
