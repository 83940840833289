#root {
    font-family: Montserrat, sans-serif;
}

html {
    scroll-behavior: auto !important;
}

// override antd Card component style
.ant-card {
    border-radius: 8px !important;
}

.ant-scrolling-effect {
    width: 100% !important;
}

span.ant-select-selection-item {
    transition: none !important;
}
