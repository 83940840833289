@import '~styles';

$avatar-background-color: #e6e6e6;

.avatar-upload-wrapper {
    text-align: center;
}

.avatar-upload-button {
    border-radius: 50%;
    font-size: 30px;
    color: white;
}

.avatar-upload-plus-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    color: white !important;
    background-color: $color-folk-main;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.upload-avatar-wrap {
    .ant-upload {
        position: relative;
        border: 2px solid white !important;
        border-radius: 50% !important;
        background-color: $avatar-background-color !important;
    }

    .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.upload-avatar-upload {
    padding-bottom: 25px;
}
