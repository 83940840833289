.divider {
    margin: 10px 0 !important;
}

@media screen and (max-width: 500px) {
    .notification-badge {
        position: absolute !important;
        left: 39vw;
        top: 41.1%;
    }
}

@media screen and (min-width: 501px) {
    .notification-badge {
        position: absolute !important;
        left: 20vw;
        top: 41.1%;
    }
}
