@import '~styles';

.selector-wrapper {
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: row;
    align-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    .icon svg {
        height: 0.8em;
        width: 0.8em;
    }

    .selector {
        width: 100% !important;
    }

    :global(.ant-select-selector) {
        background-color: $color-folk-main !important;
        border-radius: 6px !important;
        height: 100% !important;
        display: flex;
        align-items: center;

        :global(.ant-select-selection-placeholder) {
            color: white !important;
        }

        :global(.ant-select-selection-search) {
            color: white !important;
            display: flex;
            align-items: center;
        }

        :global(.ant-select-selection-item) {
            color: white !important;
        }
    }

    :global(.ant-select-clear) {
        background: $color-folk-main !important;
        color: white !important;
    }
}

:global(.ant-select-item) {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.selector-wrapper-secondary {
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: row;
    align-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    .icon svg {
        height: 0.8em;
        width: 0.8em;
        stroke: $color-folk-main;
    }

    .selector {
        width: 100% !important;
    }

    :global(.ant-select-selector) {
        background-color: $color-folk-plain-white !important;
        border-radius: 6px !important;
        border: 1px solid $color-folk-main !important;
        height: 100% !important;
        display: flex;
        align-items: center;

        :global(.ant-select-selection-placeholder) {
            color: $color-folk-main;
        }

        :global(.ant-select-selection-search) {
            color: $color-folk-main !important;
            display: flex;
            align-items: center;
        }

        :global(.ant-select-selection-item) {
            color: $color-folk-main;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }
    }

    :global(.ant-select-clear) {
        background: $color-folk-plain-white !important;
        color: $color-folk-main !important;
    }
}

.selector-disabled {
    :global(.ant-select-selector) {
        border-color: rgba(118, 118, 118, 0.3) !important;
        color: rgba(0, 0, 0, 0.25) !important;
        background: #f5f5f5 !important;
        cursor: not-allowed;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .icon svg {
        height: 0.8em;
        width: 0.8em;
        stroke: rgba(0, 0, 0, 0.25) !important;
    }

    :global(.ant-select-single) {
        color: rgba(0, 0, 0, 0.25) !important;
    }

    :global(.ant-select-selection-placeholder) {
        color: rgba(0, 0, 0, 0.25) !important;
    }

    :global(.ant-select-selection-item) {
        border-color: rgba(118, 118, 118, 0.3) !important;
        color: rgba(0, 0, 0, 0.25) !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
}

:global(.ant-select-item-option-content) {
    white-space: unset !important;
}
