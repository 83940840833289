.edit-point-media-delete {
    font-size: 20px;
}

.edit-point-media-delete-button:hover {
    cursor: pointer;
    color: #787878;
}

.edit-point-media-file {
    position: relative;
    display: inline-block;
    margin-right: 16px !important;

    .remove-icon-image {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }

    .remove-icon-video {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
    }
}
