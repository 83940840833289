@import '~styles';

.activity-card-modal {
    :global {
        .ant-modal-content {
            max-width: 448px;
            border: 1px solid $color-black-content;
            border-radius: 8px;

            .ant-modal-close-x span {
                padding-right: 32px;
                padding-top: 30px;

                &.anticon svg {
                    min-width: 24px;
                    min-height: 24px;
                }
            }

            .ant-modal-header {
                padding-top: 24px;
                padding-bottom: 24px;
                border: 1px solid $color-black-content;
                border-radius: 8px;

                .ant-modal-title {
                    font-family: 'Montserrat', serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 36px;
                    color: black;
                }
            }

            .ant-modal-body {
                min-height: 599px;
            }
        }
    }

    .activity-card-poll-form {
        .activity-card-poll-new {
            display: flex;
            align-items: center;
            cursor: pointer;

            .activity-card-poll-new-plus {
                color: gray;
                font-size: 16px;
                margin-right: 10px;
                padding-bottom: 8px;
            }

            .activity-card-poll-add {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: gray;
            }
        }
    }

    .ant-modal-footer {
        height: 88px;

        .ant-btn {
            margin-top: 21px;
            border: none;
            width: 58px;
            height: 24px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: gray;
            margin-right: 214px;
            box-shadow: none;
        }
    }

    .activity-card-create-poll {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 24px;
        margin-bottom: 20px;
        width: 158px;
        height: 40px;

        .ant-form-item,
        .ant-row,
        .ant-col,
        .ant-form-item-control-input,
        .ant-form-item-control-input-content,
        .ant-btn {
            width: 100%;
            height: 100%;
        }

        .activity-card-poll-footer-close {
            width: 100%;
            height: 100%;

            span {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .ant-btn {
            background: $color-disabled;
            border-radius: 6px;
            color: gray;
            border-color: $color-disabled;
        }
    }
}

.activity-card-poll-field-title {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 16px;
    color: black;
}

.activity-card-answer {
    position: relative;
    margin-bottom: 16px;

    .activity-card-answer-input {
        width: 100% !important;
        position: relative !important;
        border: 1px solid $color-folk-main;
        border-radius: 6px;
        height: 48px;
        padding: 12px 0 12px 20px !important;
    }

    .activity-card-delete-button {
        position: absolute;
        right: 0;
        top: 25px;
        min-width: 9px;
        min-height: 9px;
        transform: translateY(-50%);
        margin-right: 19.5px;
        color: gray;
        cursor: pointer;
    }

    &.ant-form-item {
        margin-bottom: 16px !important;

        &.ant-row.ant-col.ant-form-item-control-input.ant-form-item-control-input-content {
            height: 48px !important;
        }
    }

    .ant-form-item-control-input-content {
        height: 48px;
    }
}

.submit-poll-button {
    text-align: center;
    position: absolute;
    top: 240%;
}

.activity-card-input-container {
    position: relative;
}

.activity-card-input-container::after {
    content: '*';
    color: red;
    position: absolute;
    top: 50%;
    right: 18em;
    transform: translateY(-50%);
}

.activity-card-input-container:focus-within::after {
    display: none;
}

.activity-card-input-container[value]::after {
    display: none;
}

.poll-question {
    padding: 0 !important;
    position: relative;

    &.ant-input {
        border: 1px solid $color-folk-main;
        border-radius: 6px;
        height: 48px;
        padding: 12px 0 12px 20px !important;

        &::placeholder {
            color: $color-folk-main !important;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            position: relative;
        }

        &:focus::placeholder {
            opacity: 0;
        }
    }
}

.iconStyle {
    margin-top: 24px;
    margin-right: 24px;
}

.submit-button-poll {
    span {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
}
