@import '~styles';

.news-page-header {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;

    .news-page-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
    }

    .news-page-sub-title {
        margin-top: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}
