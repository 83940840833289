@import '~styles';

.mainContainer {
    width: 100%;
    min-height: 530px;
    display: block;

    @media (max-width: ($screen-lg - 1px)) {
        height: 388px;
    }
}

.news {
    height: 40vh !important;
    text-align: center !important;
    margin-bottom: 24px !important;
}

.buttonStyle {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
}

.divStyle {
    position: relative;
    bottom: 114px;
    width: 100%;

    @media (max-width: ($screen-lg - 1px)) {
        height: 86px;
    }
}

.ant-tabs-nav {
    margin: 0 0 12px 0 !important;
}

.divButton {
    padding: 4px 24px;
}
