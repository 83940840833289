@import '~styles';

.custom-radio-button-container input[type='radio'] {
    display: none;
}

.custom-radio-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24px;
    height: 24px;
    padding-top: 2px;
    border: 1px solid $color-folk-divider;
    border-radius: 50%;
    cursor: pointer;
}

.custom-radio-button-container i svg {
    width: 18px;
    height: 18px;
}

.custom-radio-button h3 {
    color: #979797;
    font-weight: 500;
    font-size: 1.375rem;
    letter-spacing: 1px;
    text-align: center;
}

.custom-radio-button-container input[type='radio']:checked + .custom-radio-button {
    border-color: $color-folk-main;
}

.custom-radio-button-container input[type='radio']:checked + .custom-radio-button i svg {
    fill: $color-folk-plain-white;
}

.custom-radio-button-container input[type='radio']:checked ~ .custom-radio-button {
    background: $color-folk-main;
}
