@import '~styles';

.notificationCenterPageCard {
    :global(.ant-card-body) {
        padding: 24px 0 24px !important;
    }

    .notificationCenterHeader {
        padding: 0 24px 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
    }
}
