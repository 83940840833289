@import '~styles';

.deputy-point-body {
    display: grid;
    grid-template-columns: 50px 100px 100px 100px 50px 50px;
    grid-template-rows: repeat(5, auto);
    column-gap: 10px;
    row-gap: 15px;
    justify-content: stretch;
}

.deputy-point-header {
    grid-column: 1 / 6;
    grid-row: 1 / 1;
    justify-self: center;
    align-self: center;
    text-align: center;
    font-size: large;
}

.deputy-point-rating {
    grid-column: 1 / 4;
    grid-row: 2 / 2;
    justify-self: left;
    font-size: large;
    font-weight: bold;
    color: $color-folk-main;
}

.deputy-point-group {
    grid-column: 4 / 6;
    grid-row: 2 / 2;
    justify-self: right;
    font-size: large;
    font-weight: bold;
    color: $color-folk-main;
}

.deputy-point-button-report {
    grid-column: 1 / 3;
    grid-row: 3 / 3;
    justify-self: center;
    margin: 6px;
}

.deputy-point-button-foto {
    grid-column: 4 / 6;
    grid-row: 3 / 3;
    justify-self: center;
    margin: 6px;
}

.deputy-point-card-date {
    grid-column: 1 / 1;
    grid-row: 4 / 4;
    justify-self: left;
}

.deputy-point-card-edit {
    grid-column: 2 / 2;
    grid-row: 4 / 4;
    justify-self: left;
}

.deputy-point-not-loaded-report {
    grid-column: 4 / 4;
    grid-row: 4 / 4;
    justify-self: right;
}

.deputy-point-not-answers {
    grid-column: 5 / 5;
    grid-row: 4 / 4;
    justify-self: right;
}

.deputy-point-question {
    margin-top: 5px;
}

.orange-buttons-modal {
    .subtitle {
        font-weight: bold;
    }
}
