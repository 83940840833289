.cardTitleBody {
    display: flex;
}

.cardTitleText {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

.cardTitleActionBar {
    display: flex;
    gap: 16px;
    margin-left: 16px;
}
