@import '~styles';

.activity-form-fields-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px !important;

    :global {
        .ant-select {
            height: 48px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            padding: 7px 11px;
            height: 48px;
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
            height: 48px;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            text-align: left;
        }

        .date-picker-field.ant-picker,
        .time-picker-field.ant-picker {
            border-radius: 6px;
            height: 48px;
            max-width: 188px;

            & input {
                text-align: left !important;
            }

            & input::placeholder {
                text-align: left !important;
            }

            &:has(input:placeholder-shown)::after {
                content: '*';
                color: red;
                position: absolute;
                top: 40%;
                left: 4.2em;
                transform: translateY(-50%);
                cursor: text;
            }

            :global(.ant-picker-suffix) {
                position: absolute;
                right: 0;
                opacity: 0;
            }
        }

        .date-picker-field.ant-picker {
            &:has(input:placeholder-shown)::after {
                content: '*';
                color: red;
                position: absolute;
                top: 40%;
                left: 3.4em;
                transform: translateY(-50%);
                cursor: text;
            }
        }

        .ant-form-item-control-input-content {
            width: 100% !important;

            .disabled-submit-button {
                width: 100% !important;
                padding: 0 !important;
            }
        }
    }

    textarea::placeholder {
        text-align: left;
    }
}

.activity-card-poll {
    :global {
        .select-field .ant-select-selection-search-input {
            text-align: left;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
            content: '*';
            color: red;
            position: absolute;
            top: 40%;
            left: 29.5em;
            transform: translateY(-50%);
            cursor: text;
            visibility: visible;
        }

        .ant-input-textarea-show-count:has(textarea:placeholder-shown)::after {
            content: '*';
            color: red;
            position: absolute;
            top: 8%;
            left: 6.25em;
            transform: translateY(-50%);
            cursor: text;
        }
    }
}

.activity-card-poll-title {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    & .activity-delete-interview {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #808080;
        cursor: pointer;
    }
}

.activity-filters {
    margin-bottom: 24px;

    :global(.ant-form-item) {
        margin: 0 !important;
    }
}

.activity-card-button-group {
    display: flex;

    .activity-card-button {
        flex: 1;
        max-width: 188px;
        justify-content: start;

        &:first-child {
            margin-right: 24px;
        }
    }
}

.activity-card-submit-new {
    display: flex;
    padding-top: 24px;
    width: 100% !important;
    justify-content: space-between !important;

    :global {
        .disabled-submit-button {
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100% !important;
        }

        .submit-button {
            margin-left: 0% !important;
            max-width: 100% !important;
        }

        .ant-form-item {
            width: 100% !important;
            margin-bottom: 0;
        }

        .ant-btn.ant-btn-block {
            width: 100% !important;
            margin-bottom: 10px;
            border-radius: 6px !important;

            &:nth-child(2) {
                background-color: $color-red-star;
                color: $color-folk-plain-white;
            }
        }
    }
}

.activity-card-submit {
    padding-top: 24px;

    .activity-card-cancel[disabled] {
        background: #f5f5f5 !important;
        color: rgba(0, 0, 0, 0.25) !important;
    }

    .activity-card-cancel {
        background: #e93323 !important;
        color: #fff !important;
    }

    :global {
        .disabled-submit-button {
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100% !important;
        }

        .submit-button {
            margin-left: 0% !important;
            max-width: 100% !important;
        }

        .ant-form-item {
            width: 100% !important;
            margin-bottom: 0;
        }

        .ant-btn.ant-btn-block {
            width: 100% !important;
            margin-bottom: 10px;
            border-radius: 6px !important;

            &:nth-child(2) {
                background-color: $color-red-star;
                color: $color-folk-plain-white;
            }
        }
    }
}

.activity-card-poll-survey {
    &-active {
        display: flex;
        cursor: pointer;
    }

    &-active:hover {
        background-color: $color-folk-gray-background;
    }

    &-disabled {
        display: flex;
    }
}

.activity-card-poll-icon {
    margin: 12px 0;
    position: relative;
    background-color: $color-folk-main;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;

    & svg {
        color: $color-folk-plain-white;
        position: absolute;
        top: 33%;
        left: 36%;
    }
}

.activity-card-poll-text {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 12px;
    word-wrap: break-word;
    word-break: break-word;

    .activity-card-poll-text-title {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .activity-card-poll-text-desc {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $color-gray;
    }
}
