@import '~styles';

.chat-button {
    height: max-content !important;

    .chat-button-content {
        display: flex;
        gap: 8px;

        .chat-icon {
            fill: $color-folk-plain-white;

            path {
                fill: inherit;
            }
        }
    }
}

.disabled-button {
    cursor: not-allowed !important;
    background: $color-disabled !important;
    border-color: $color-disabled !important;
    color: $color-folk-plain-black !important;

    span {
        font-weight: 500;
    }
}
